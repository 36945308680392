import { callExternalApi } from "./external-api.service";


const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;


export const createReview = async (accessToken, review) => {
	const config = {
		url: `${apiServerUrl}/api/contact/review`,
		method: "POST",
		data: review,
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};

export const createRequest = async (accessToken, review) => {
	const config = {
		url: `${apiServerUrl}/api/contact/request`,
		method: "POST",
		data: review,
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};