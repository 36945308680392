import { blue, volcano } from '@ant-design/colors'
import { VerticalAlignMiddleOutlined, ReloadOutlined, GatewayOutlined } from '@ant-design/icons';
import { ConfigProvider, Switch, Space, Button, Image, notification, Tooltip, Modal } from 'antd';
import { IconBuyOrder, IconEMA, IconMA, IconSellOrder, IconSignalBuyL1TT, IconSignalBuyL2TT, IconSignalSellL1TT, IconSignalSellL2TT, svgBuyOrder, svgSellOrder } from './Icons'
import React, { useContext, useState, useEffect, useRef } from "react";
import { createRoot } from 'react-dom/client';
import UserContext from '../components/UserContext';
import { renderToString } from 'react-dom/server';
import { useAuth0 } from "@auth0/auth0-react";
import { init, getInstanceByDom } from 'echarts';
import { getTickerHistory, getTickerQuote, getTickerInfos } from "../services/ticker.service";
import { getTickerDemoHistory, getTickerDemoQuote, getTickerDemoInfos } from "../services/demo.service";
import { addBusinessDays, formatNumberIn, formatNumber, displayData, formatPourcent, getLogoUrl, formatDateToBetterReading } from '../utils/utils'
import { isMobile } from '../utils/utils';
import { TagResistanceTT, TagSupportTT } from './Tags';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ACTION_TYPE_BUY_COLOR, ACTION_TYPE_SELL_COLOR, ANTD_THEME_TOKEN, COLOR_PRIMARY, DATE_YEAR_FIRST, DATE_DAY_FIRST, COLOR_SIGNAL_BUY_L1, COLOR_SIGNAL_BUY_L2, COLOR_SIGNAL_SELL_L1, COLOR_SIGNAL_SELL_L2, TOOLTIP_ENTER_DELAY } from '../JTIConst';
import ModalAI from './modals/ModalAI';
import { NotifUpgradePlan } from './Notifications';
import { isAllowedAGIEarningsSummary, isAllowedCandleProMode } from "../services/authorization.service";
import { BuySignalDesc, InfoArkInvest, Measure, Reset, SellSignalDesc, ShowSuppResis, TakeProfitDesc, UnauthorizedAGIEarningsSummary, UnauthorizedCandleProMode } from './TempLang';


// PARAMETERS //

const IS_MOBILE = isMobile()
const CANDLE_SLIDER_ENABLE = false

// Info: 257 business days per year
const CHART_DAYS_OFFSET = 120
const CHART_ZOOM_DEFAULT_OFFSET = IS_MOBILE ? 2 : 20
const CHART_ZOOM_DEFAULT_HISTORY = IS_MOBILE ? 300 : 548
const CHART_ZOOM_MIN_SPAN = 40
const CHART_ZOOM_MAX_SPAN = 5479            // Env 15y
const CHART_ZOOM_THROTTLE = 40
const CHART_ANIM_SIGNAL_DURATION = 1000
const REAL_TIME_INTERVAL = 5000             // Each 5sec

// Chart layout configurations
const CHART_CONTAINER_HEIGHT = IS_MOBILE ? '614px' : '642px'
const CHART_CONTAINER_HEIGHT_WITH_TRACKER = IS_MOBILE ? '662px' : '690px'
const CHART_POSITION_TOP = IS_MOBILE ? 73 : 50
const CHART_POSITION_LEFT = 0
const CHART_POSITION_RIGHT = IS_MOBILE ? 38 : 45
const CHART_HEIGHT = IS_MOBILE ? (CANDLE_SLIDER_ENABLE ? 488 : 520) : 570
const CHART_VOL_HEIGHT = 90
const CHART_VOL_TOP = CHART_POSITION_TOP + CHART_HEIGHT - CHART_VOL_HEIGHT
const CHART_ED_Y = IS_MOBILE ? (CANDLE_SLIDER_ENABLE ? '90.5%' : 585) : 612
const CHART_FUNDS_TRADES_TOP = CHART_POSITION_TOP + CHART_HEIGHT + 54
const TREND_TOP_POINT = IS_MOBILE ? '20%' : '10%'
const Y_AXIS_NAME_GAP = IS_MOBILE ? 27 : 33
const CHART_LOADING_OPTS = { text: '', color: COLOR_PRIMARY, spinnerRadius: 22, lineWidth: 4 }

// Chart style
const CANDLE_SELECTED = {
    color: 'black',
    borderColor: 'black',
    opacity: 1
}
const CANDLE_STYLE_PRO = {
    color: 'rgb(20, 177, 67, 0)',
    color0: 'rgb(239, 35, 42, 0)',
    borderColor: 'rgb(20, 177, 67, 1)',
    borderColor0: 'rgb(239, 35, 42, 1)',
    opacity: 1
}
const CANDLE_STYLE_LIGHT = {
    color: 'rgb(0, 0, 0, 0.1)',
    color0: 'rgb(0, 0, 0, 0.1)',
    borderColor: 'rgb(0, 0, 0, 0.3)',
    borderColor0: 'rgb(0, 0, 0, 0.3)',
    opacity: 1
}
const CANDLE_FIRST_ABOVE_MA = {
    color: '#2478B7',
    color0: '#2478B7',
    borderColor: '#2478B7',
    borderColor0: '#2478B7',
    opacity: 0.9
}
const CANDLE_FIRST_ABOVE_EMA = {
    color: '#f64d2a',
    color0: '#f64d2a',
    borderColor: '#f64d2a',
    borderColor0: '#f64d2a',
    opacity: 0.9
}
const VOL_STYLE_LIGHT = {
    color: 'rgb(0, 0, 0, 0.1)',
    borderColor: 'rgb(0, 0, 0, 0.2)',
    borderWidth: 1,
    opacity: 1
}
const PRICE_STYLE = {
    padding: [4, 5, 4, 5],
    color: 'white',
    borderWidth: 1,
    borderRadius: 3,
    fontWeight: 500
}
const SUPP_RESIS_COLOR = (opacity = 1) => { return 'rgb(239, 35, 42, ' + opacity + ' )' }
const SUPP_RESIS_STYLE = {
    width: 3,
    color: SUPP_RESIS_COLOR(0.15),
    type: 'solid'
}
const YAXIS_LINE_STYLE = 'rgb(0, 0, 0, 0.06)'

// TT style
const ttContainerStyle = 'style="line-height: 1.9em;"'
const ttHeaderStyle = 'style="margin-bottom: 0.7em; font-weight: bold; font-size: 1.1em"'
const ttSubTitleStyle = 'style="margin: 1em 0 0.4em 0; font-weight: bold;"'

const getPriceLineStyle = (openQuote, closeQuote) => {

    // Set price color
    let color = 'grey'
    if (closeQuote > openQuote) {
        color = 'green'
    } else if (closeQuote < openQuote) {
        color = '#C62828'
    }

    // Set price position
    let distance = 2
    if (closeQuote > 99)
        distance = -2
    else if (closeQuote > 999)
        distance = -4

    return {
        symbol: 'none',
        label: {
            show: true,
            ...PRICE_STYLE,
            backgroundColor: color,
            distance: distance,
        },
        lineStyle: {
            color: 'grey',
            width: 0.6
        },
        emphasis: {
            disabled: true
        }
    }
}
const axisPointerStyle = {
    show: true,
    shadowStyle: {
        color: 'rgb(190, 190, 190, 0.1)'
    },
    label: {
        padding: [4, 5, 4, 5]
    }
}

// SIGNALS
const signalsEmphasis = {
    disabled: false,
    focus: 'series',
    scale: 1.8,
}
const signalsCommonConfigs = {
    type: 'scatter',
    animation: true,
    symbolSize: 8,
    itemStyle: {
        opacity: 1
    },
    emphasis: signalsEmphasis,
    animationDuration: CHART_ANIM_SIGNAL_DURATION,
    animationDurationUpdate: CHART_ANIM_SIGNAL_DURATION,
}

// Orders
const orderCommonConfigs = {
    type: 'scatter',
    animation: true,
    symbolSize: 13,
    itemStyle: {
        opacity: 1
    },
    symbolKeepAspect: true,
    emphasis: signalsEmphasis,
    animationDuration: CHART_ANIM_SIGNAL_DURATION,
    animationDurationUpdate: CHART_ANIM_SIGNAL_DURATION,
}
const createBuyOrder = (entry) => {
    return {
        name: '<div ' + ttSubTitleStyle + '">' + renderToString(<><IconBuyOrder /> &nbsp;</>) + entry.actionType + ' Order</div>' +
            '- Date: ' + dayjs.utc(entry.date).format(DATE_YEAR_FIRST) +
            '<br />- Shares: ' + entry.shares +
            '<br />- Price: $ ' + formatNumber(entry.price) + ' ' + quote.currency +
            '<br />- Fee: $ ' + formatNumber(entry.fee) + ' ' + quote.currency +
            '<br />- Total: $ ' + ((entry.price * entry.shares) + entry.fee).toFixed(2) + ' ' + quote.currency,
        value: [dayjs.utc(entry.date).format(DATE_YEAR_FIRST), entry.price],
    }
}
const createSellOrder = (entry) => {
    return {
        name: '<div ' + ttSubTitleStyle + '">' + renderToString(<><IconSellOrder /> &nbsp;</>) + entry.actionType + ' Order</div>' +
            '- Date: ' + dayjs.utc(entry.date).format(DATE_YEAR_FIRST) +
            '<br />- Shares: ' + entry.shares +
            '<br />- Price: $ ' + formatNumber(entry.price) + ' ' + quote.currency +
            '<br />- Fee: $ ' + formatNumber(entry.fee) + ' ' + quote.currency +
            '<br />- Total: $ ' + formatNumber((entry.price * entry.shares) + entry.fee) + ' ' + quote.currency +
            '<br />- Gain/Loss : <b>' + entry.gainLoss?.toFixed(2) + '%</b>',
        value: [dayjs.utc(entry.date).format(DATE_YEAR_FIRST), entry.price],
    }
}

// Markpoints earnings and dividends
const markpointCommonConfigs = {
    symbolSize: 15,
}

const sliderZoomStyle = {
    bottom: 0,
    brushSelect: false,
    borderColor: 'rgba(217,217,217,0.30)',
    fillerColor: 'rgba(198,40,40,0.10)',
    dataBackground: {
        lineStyle: {
            color: '#d9d9d9'
        },
        areaStyle: {
            color: '#d9d9d9'
        }
    },
    selectedDataBackground: {
        lineStyle: {
            color: '#d9d9d9'
        },
        areaStyle: {
            color: '#d9d9d9'
        }
    },
    handleStyle: {
        borderColor: '#C62828'
    },
    emphasis: {
        handleStyle: {
            borderColor: '#C62828'
        }
    }
}


// SHARED VARIABLES //

dayjs.extend(utc)
let quote = null


/**
 * @param {ticker} Selected ticker
 * @param {tkrInfos} Selected ticker
 * @param {interval} Selected history interval: 1d, 1h
 * @param {reloadKey} Allow to rerender component from outside hook
 * @returns 
 * 
 * Display candlestick chart from either @ticker or @tkrInfos param
 */
const CandlestickChart = ({ modal, ...props }) => {

    var currentTicker = props.ticker
    if (!currentTicker)
        currentTicker = props.tkrInfos?.ticker

    const { getAccessTokenSilently } = useAuth0()
    const { userContext, updateUserContext } = useContext(UserContext);
    const chartRef = useRef(null)
    const [loading, setLoading] = useState(true)
    const [options, setOptions] = useState({})
    const [seriesTrend, setSeriesTrend] = useState({})
    const [seriesSuppResis, setSeriesSuppResis] = useState([])
    const [stateBtnSuppResis, setStateBtnSuppResis] = useState(false)
    const [stateBtnBrush, setStateBtnBrush] = useState(false)
    const [chartStyleSwitch, setChartStyleSwitch] = useState(false)
    const [enableRealTime, setEnableRealTime] = useState(false)
    const [lastUpdate, setLastUpdate] = useState('')
    const [openModalAGI, setOpenModalAGI] = useState(false)
    const [modalAGIIcon, setModalAGIIcon] = useState('')
    const [modalAGITitle, setModalAGITitle] = useState('')
    const [modalAGIText, setModalAGIText] = useState('')
    const [agi, setAGI] = useState(new Map())
    const [haveFundTrades, setHaveFundTrades] = useState(false)
    const [chartContainerHeight, setChartContainerHeight] = useState(props.height ?? CHART_CONTAINER_HEIGHT)

    // Notification
    const [notifApi, contextHolder] = notification.useNotification()


    // Reset chart buttons
    const resetChartButtons = () => {
        // Disable chart buttons
        setStateBtnSuppResis(false)
        setStateBtnBrush(false)
    }

    // Manage chart mode Light / Pro
    const updChartMode = (checked) => {

        // MARK SEC - Candle Pro mode
        // If not demo and switch to Pro and not allowed Pro mode
        if (!props.isDemo && checked && !isAllowedCandleProMode(userContext)) {
            notifApi.info(NotifUpgradePlan(UnauthorizedCandleProMode))
            return;
        }

        if (chartRef.current !== null) {

            // Get chart instance
            const chart = getInstanceByDom(chartRef.current);

            // Update first take profit candles style
            /*const serieCandle = chart.getOption().series.find(item => item.name === 'Candle')
            serieCandle.data.forEach(function(obj) {
                if (obj.groupID === 'signalFirstAboveMA' || obj.groupID === 'signalFirstAboveEMA') {
                    obj.itemStyle.opacity = checked ? 1 : 0.5
                }
            })*/

            chart.setOption({
                yAxis: {
                    splitLine: {
                        lineStyle: {
                            color: YAXIS_LINE_STYLE
                        }
                    }
                },
                series: [
                    {
                        name: 'Candle',
                        itemStyle: checked ? CANDLE_STYLE_PRO : CANDLE_STYLE_LIGHT,
                        markLine: {
                            data: checked ? seriesTrend : [],
                        }
                    }
                ]
            })

            if (!props.isDemo) {
                userContext.chartMode = checked ? "PRO" : "LIGHT"
                updateUserContext(userContext)
            }
            setChartStyleSwitch(checked)
            resetChartButtons()
        }
    }

    // Toggle support and resistance in the chart
    const toggleSuppResis = () => {

        // MARK SEC - Candle support and resistance feature
        // If not demo and switch to Pro and not allowed Pro mode
        if (!props.isDemo && !isAllowedCandleProMode(userContext)) {
            notifApi.info(NotifUpgradePlan(UnauthorizedCandleProMode))
            return;
        }

        if (chartRef.current !== null) {

            // Get chart instance
            const chart = getInstanceByDom(chartRef.current);
            const option = chart.getOption();
            const currMarkLine = option.series.find(s => s.name === 'Candle').markLine

            chart.setOption({
                yAxis: {
                    splitLine: {
                        lineStyle: {
                            color: stateBtnSuppResis === false ? 'rgb(0, 0, 0, 0.0)' : YAXIS_LINE_STYLE
                        }
                    }
                },
                series: [
                    {
                        name: 'Candle',
                        markLine: {
                            data: stateBtnSuppResis === false ? seriesSuppResis.concat(currMarkLine.data) :
                                chartStyleSwitch === false ? [] : seriesTrend
                        }
                    }
                ]
            })

            setStateBtnSuppResis(prevValue => !prevValue)
        }
    }

    // Chart zoom reset button
    const toggleZoomReset = () => {

        if (chartRef.current !== null) {

            const chart = getInstanceByDom(chartRef.current);

            chart.dispatchAction({
                type: 'restore',
            })
        }

        resetChartButtons()
    }

    // Chart brush button
    const toggleBrush = () => {

        if (chartRef.current !== null) {

            const chart = getInstanceByDom(chartRef.current);

            if (stateBtnBrush) {
                chart.dispatchAction({
                    type: 'takeGlobalCursor',
                    key: 'brushEnd',
                })
            } else {
                chart.dispatchAction({
                    type: 'takeGlobalCursor',
                    key: 'brush',
                    brushOption: {
                        brushType: 'rect',
                        // brushMode: string
                    }
                })
            }
        }

        setStateBtnBrush(prevValue => !prevValue)
    }


    // MARK : Update chart for REAL TIME
    // https://stackoverflow.com/questions/53024496/state-not-updating-when-using-react-state-hook-within-setinterval 
    const updateCandle = async () => {

        let quote = null
        if (props.isDemo) {
            const quoteResp = await getTickerDemoQuote(currentTicker)
            quote = quoteResp.data
        } else {
            const accessToken = await getAccessTokenSilently()
            const quoteResp = await getTickerQuote(accessToken, currentTicker)
            quote = quoteResp.data
        }

        // Updating options state is not smooth > use echarts instance
        // setOptions((prevOpts) => {
        //     ...
        //     return { ...prevOpts }
        // })

        if (chartRef.current !== null && 'open' in quote) {

            // Get chart instance
            const chart = getInstanceByDom(chartRef.current);
            const prevOpts = chart.getOption()
            let candleData = prevOpts.series.find(s => s.name === 'Candle').data
            let volData = prevOpts.series.find(s => s.name === 'Volume').data

            if (prevOpts?.jti_idxLastDayHisto) {
                const indexCurrentDay = prevOpts.jti_idxLastDayHisto

                // Testing...
                // const rand = Math.floor(Math.random() * 10) + 1
                // quote.close = quote.close + rand
                // quote.high = quote.high + rand

                candleData[indexCurrentDay] = { value: [quote.open.toFixed(2), quote.close.toFixed(2), quote.low.toFixed(2), quote.high.toFixed(2)] }
                volData[indexCurrentDay] = quote.volume

                const priceLine = [
                    {
                        yAxis: quote.close,
                        xAxis: prevOpts.xAxis[0].data[indexCurrentDay],
                    },
                    {
                        name: quote.close.toFixed(2),
                        yAxis: quote.close,
                        xAxis: 'max',
                        ...getPriceLineStyle(quote.open, quote.close)
                    }]

                chart.setOption({
                    series: [
                        {
                            name: 'Candle',
                            data: candleData,
                        },
                        {
                            name: 'Volume',
                            data: volData,
                        },
                        {
                            name: 'Price Line',
                            markLine: {
                                animation: false,
                                data: [priceLine],
                            }
                        }
                    ]
                })
            }
        }
    }

    // NOTUSED : Update orders on chart dynamically
    const updOrders = () => {
        if (!props.isDemo) {
            const scatterBuyOrder = userContext.stockTradingBook.filter(entry => entry.actionType === 'BUY' && entry.symbol === currentTicker).map(entry => createBuyOrder(entry))
            const scatterSellOrder = userContext.stockTradingBook.filter(entry => entry.actionType === 'SELL' && entry.symbol === currentTicker).map(entry => createSellOrder(entry))

            // Get chart instance
            const chart = getInstanceByDom(chartRef.current);
            chart.setOption({
                series: [
                    {
                        name: 'Buy Order',
                        data: scatterBuyOrder
                    },
                    {
                        name: 'Sell Order',
                        data: scatterSellOrder
                    }
                ]
            })
        }
    }

    // AGI
    const showEarningsAGIModal = (icon, title, text) => {
        setModalAGIIcon(icon)
        setModalAGITitle(title)

        // MARK SEC - AGI earnings
        if (props.isDemo || isAllowedAGIEarningsSummary(userContext))
            setModalAGIText(text)
        else {
            setModalAGIText(false)
            notifApi.info(NotifUpgradePlan(UnauthorizedAGIEarningsSummary))
        }

        setOpenModalAGI(true)
    }


    // EFFECTS =================================================================================================================================== //

    useEffect(() => {

        currentTicker = props.ticker
        if (!currentTicker)
            currentTicker = props.tkrInfos?.ticker

        const fetchData = async () => {

            setLoading(true)
            resetChartButtons()

            // Set chart style switch state
            let candleSwitch = userContext?.chartMode == "PRO"
            setChartStyleSwitch(candleSwitch)
            var candleStyle = candleSwitch ? CANDLE_STYLE_PRO : CANDLE_STYLE_LIGHT

            // Request data
            let infos = props.tkrInfos
            var dataHistory = null
            let earningsRes = []
            let dividendsRes = []
            if (props.isDemo) {
                if (!infos) {
                    const infosRes = await getTickerDemoInfos(currentTicker)
                    infos = infosRes.data
                }
                dataHistory = await getTickerDemoHistory(currentTicker, props.interval)
                const quoteResp = await getTickerDemoQuote(currentTicker)
                quote = quoteResp.data
            } else {
                const accessToken = await getAccessTokenSilently()

                // Get tkr infos data for splits, earnings, dividends, lastUpdate
                if (!infos) {
                    const infosRes = await getTickerInfos(accessToken, currentTicker)
                    infos = infosRes.data
                }

                // History
                dataHistory = await getTickerHistory(accessToken, currentTicker, props.interval)

                // Manage forbiden cases
                if (dataHistory.error)
                    return

                // Last quote
                const quoteResp = await getTickerQuote(accessToken, currentTicker)
                quote = quoteResp.data
            }

            // Earnings & dividends
            if (props.interval === '1d') {
                earningsRes = infos.earnings ?? []
                dividendsRes = infos.dividends ?? []
                setLastUpdate(infos.oneDay?.lastUpdate ?? '')
                setAGI(infos.agi)
            } else if (props.interval === '1h') {
                setLastUpdate(infos.oneHour?.lastUpdate ?? '')
            }

            var dataFiltered = dataHistory.data.filter(d => d.open !== 'NaN')

            // If daily > remove the times from the dates
            if (props.interval === '1d') {
                dataFiltered = dataFiltered.map((obj) => ({
                    ...obj,
                    datetime: obj.datetime.slice(0, -6),
                }))
            }
            // console.log(JSON.stringify(dataFiltered))

            // Chart x-axis
            const timestamp = dataFiltered.map(d => d.datetime)


            // CANDLES //

            // CODE : JS object list iteration
            const seriesCandle = dataFiltered.map((d) => {
                let value = [d.open.toFixed(2), d.close.toFixed(2), d.low.toFixed(2), d.high.toFixed(2)]

                // Set Take Profit candles
                if (d.signalFirstAboveMA) {
                    return {
                        name: '<div style="margin: 10px 0 0 0;">' + renderToString(<IconMA />) + '&nbsp; <b>Take Profit L2</b></div>',
                        groupID: 'signalFirstAboveMA',
                        value: value,
                        itemStyle: CANDLE_FIRST_ABOVE_MA
                    }
                } else if (d.signalFirstAboveEMA) {
                    return {
                        name: '<div style="margin: 10px 0 0 0;">' + renderToString(<IconEMA />) + '&nbsp; <b>Take Profit L1</b></div>',
                        groupID: 'signalFirstAboveEMA',
                        value: value,
                        itemStyle: CANDLE_FIRST_ABOVE_EMA
                    }
                } else
                    // Be consitent on candle format data for reading in the code
                    return { value: value }
            })


            // VOLUMES //

            const seriesVol = dataFiltered.map((d) => (d.volume))

            // Index for the last row of the df
            var idxLastDayHisto = dataFiltered.length - 1


            // TREND //

            const seriesTrendLocal = (dataFiltered.filter(d => d.shift === true).map(d => (
                [{
                    name: ((d.trendFlag === 'Bull') ? 'Bull Flag' : 'Bear Flag'),
                    y: TREND_TOP_POINT,
                    xAxis: d.datetime,
                    symbol: 'none',
                    label: {
                        show: false
                    },
                    lineStyle: {
                        color: ((d.trendFlag === 'Bull') ? 'green' : 'red'),
                        width: 0.8
                    },
                    emphasis: {
                        label: {
                            show: true,
                            position: 'start',
                            padding: -15,
                            color: ((d.trendFlag === 'Bull') ? 'green' : 'red'),
                        }
                    }
                },
                {
                    yAxis: d.high,
                    xAxis: d.datetime,
                    symbol: 'circle',
                    symbolSize: 0,
                    label: {
                        show: false
                    },
                    emphasis: {
                        label: {
                            show: true,
                            color: ((d.trendFlag === 'Bull') ? 'green' : 'red'),
                        }
                    },
                }]))
            )
            setSeriesTrend(seriesTrendLocal)


            // SUPPORT & RESISTANCE //

            const sSuppResis = dataFiltered.filter(d => d.support === true || d.resistance === true).map(d =>
            ({
                name: d.support === true ? 'Support' : 'Resistance',
                yAxis: d.support === true ? d.low : d.high,
                label: {
                    show: false,
                    position: 'insideEndTop',
                    ...PRICE_STYLE,
                    backgroundColor: SUPP_RESIS_COLOR()
                },
                lineStyle: SUPP_RESIS_STYLE,
                emphasis: {
                    label: {
                        show: true,
                    }
                }
            }))
            setSeriesSuppResis(sSuppResis)


            // FUNDS TRADES //

            let arkTrades = []
            const arkFundTrades = infos.funds?.ark?.trades
            if (props.interval === '1d' && infos.assetType === 'STOCK' && arkFundTrades?.length) {

                // Redefine chart layout
                setChartContainerHeight(props.height ?? CHART_CONTAINER_HEIGHT_WITH_TRACKER)

                const groupTradesByDate = (trades) => {
                    const groupedTrades = {};

                    trades.forEach(trade => {
                        if (!groupedTrades[trade.date]) {
                            groupedTrades[trade.date] = [];
                        }
                        groupedTrades[trade.date].push(trade);
                    })

                    return Object.keys(groupedTrades).map(date => ({
                        date,
                        trades: groupedTrades[date],
                        totalShares: groupedTrades[date].reduce((sum, trade) => sum + trade.shares, 0)
                    }))
                }

                arkTrades = groupTradesByDate(arkFundTrades).map(group => {
                    return {
                        name: 'Fund Trade',
                        value: [group.date.substring(0, 10), 0],
                        symbol: group.trades[0].actionType === 'Buy' ? 'path://' + svgBuyOrder : 'path://' + svgSellOrder,
                        itemStyle: {
                            color: group.trades[0].actionType === 'Buy' ? ACTION_TYPE_BUY_COLOR : ACTION_TYPE_SELL_COLOR,

                        },
                        tooltip: {
                            show: true,
                            trigger: 'item',
                            formatter: function (params) {
                                const tradesHtml =
                                    '<div ' + ttSubTitleStyle + '">Date: ' + group.trades[0].date.substring(0, 10) + '</div>' +
                                    group.trades.map(trade => (
                                        '<div ' + ttSubTitleStyle + '">ETF ' + trade.etf + '</div>' +
                                        'Action Type: <b>' + trade.actionType + '</b>' +
                                        '<br />Shares: <b>' + trade.shares + '</b>'
                                    )).join('');

                                return '<div ' + ttContainerStyle + '><div ' + ttHeaderStyle + '>Ark Invest</div>' +
                                    tradesHtml + '<div ' + ttSubTitleStyle + '>Total Shares: ' + group.totalShares + '</div></div>';
                            }
                        }
                    }
                })

                setHaveFundTrades(true)
            } else {
                // Redefine chart layout
                setHaveFundTrades(false)
                setChartContainerHeight(props.height ?? CHART_CONTAINER_HEIGHT)
            }


            // EARNINGS & DIVIDENDS //

            let earnings = []
            let dividends = []
            if (props.interval === '1d' && infos.assetType === 'STOCK') {

                // CODE : JS Map iteration
                earnings = Object.entries(earningsRes).map(([k, v]) => {

                    let date = k.slice(0, -15)

                    // Define earning color
                    let color = 'grey'
                    if (v.surprise < 0)
                        color = '#f64d2a'
                    else if (v.surprise > 0)
                        color = 'green'

                    return {
                        ...markpointCommonConfigs,
                        symbol: 'roundRect',
                        // Add marker for earnings with agi report
                        name: 'Earnings' + (infos.agi?.hasOwnProperty('earnings_' + date) ? ' AGI' : ''),
                        coord: [date, 0],
                        value: 'E',
                        label: {
                            color: color,
                            fontSize: 11,
                            fontWeight: 'bold'
                        },
                        itemStyle: {
                            borderColor: color,
                            borderWidth: 1,
                            color: '#fff',
                        },
                        tooltip: {
                            show: true,
                            trigger: 'item',
                            formatter: function (params) {

                                // To see what is available for tooltip data
                                // console.log(JSON.stringify(params))

                                return '<div ' + ttContainerStyle + '><div ' + ttHeaderStyle + '>Earnings</div>' +
                                    'Date: <b>' + params.data.coord[0] + '</b>' +
                                    '<br />EPS Estimate: <b>' + v.epsEstimate + '</b>' +
                                    '<br />Reported EPS: <b>' + displayData(v.reportedEps, '') + '</b>' +
                                    '<br />Surprise: <b>' + displayData(formatPourcent(v.surprise), '') + '</b>' +
                                    '</div>'
                            }
                        }
                    }
                })

                dividends = Object.entries(dividendsRes).map(([k, v]) => ({
                    ...markpointCommonConfigs,
                    symbol: 'circle',
                    name: 'Dividends',
                    coord: [k.slice(0, -15), 0],
                    value: 'D',
                    label: {
                        color: COLOR_SIGNAL_BUY_L2,
                        fontSize: 11,
                        fontWeight: 'bold'
                    },
                    itemStyle: {
                        borderColor: COLOR_SIGNAL_BUY_L2,
                        borderWidth: 1,
                        color: '#fff',
                    },
                    tooltip: {
                        show: true,
                        trigger: 'item',
                        formatter: function (params) {
                            return '<div ' + ttContainerStyle + '><div ' + ttHeaderStyle + '>Dividends</div>' +
                                'Date: <b>' + params.data.coord[0] + '</b>' +
                                '<br />Amount: <b>' + v + '</b>' +
                                '</div>'
                        }
                    }
                }))
            }


            // MARK : Enable REAL TIME
            const seriesRealTime = []
            const priceLine = []
            let closeQuote = seriesCandle[idxLastDayHisto].value[1]
            let openQuote = seriesCandle[idxLastDayHisto].value[0]

            // If last quote date is != last history date, otherwire AI Model already ran
            // And candle interval is 1d
            if (quote.timestamp !== null && quote.timestamp.substring(0, 10) != timestamp[idxLastDayHisto] && props.interval == '1d') {
                // if (true) {
                // Market Open > Enable real time
                setEnableRealTime(true)
                idxLastDayHisto = dataFiltered.length

                timestamp.push(quote.timestamp.substring(0, 10))
                seriesCandle.push([quote.open.toFixed(2), quote.close.toFixed(2), quote.low.toFixed(2), quote.high.toFixed(2)])
                seriesVol.push(quote.volume)
                closeQuote = quote.close.toFixed(2)
                openQuote = quote.open.toFixed(2)
            }

            // Price line
            priceLine.push([{
                yAxis: closeQuote,
                xAxis: timestamp[idxLastDayHisto],
            },
            {
                name: closeQuote,
                yAxis: closeQuote,
                xAxis: 'max',
                ...getPriceLineStyle(openQuote, closeQuote),
            }])

            // Define chart right position depending of Y axis values
            let chartPositionRight = CHART_POSITION_RIGHT
            let chartYAxisLabelLeftPadding = IS_MOBILE ? 7 : 12
            if (closeQuote > 999.99) {
                chartPositionRight += 13
                chartYAxisLabelLeftPadding += 13
            }

            // MARK : Add right margin to the candlestick chart
            const invisibleStyle = {
                silent: true,
                select: {
                    disabled: true
                },
                itemStyle: {
                    opacity: 0
                },
                emphasis: {
                    disabled: true
                }
            }

            addBusinessDays(timestamp, CHART_DAYS_OFFSET)
            for (let i = 0; i < CHART_DAYS_OFFSET; i++) {

                // Don't use push([]) cause otherwise brush won't work
                seriesCandle.push({
                    value: [closeQuote, closeQuote, closeQuote, closeQuote],
                    ...invisibleStyle
                })
                seriesVol.push(0)

                seriesRealTime.push({
                    value: [timestamp[idxLastDayHisto + i], closeQuote],
                    ...invisibleStyle
                })
            }

            // console.log('timestamp: ' + JSON.stringify(timestamp))
            // console.log('seriesCandle: ' + JSON.stringify(seriesCandle))

            // EMA and MA
            const seriesMA50 = dataFiltered.map((d) => (d.ma50))
            const seriesEMA30 = dataFiltered.map((d) => (d.ema30))


            // SIGNALS //

            // Use scatter name attribute for meta data
            const mapFunc = (d, isLow, sig, color) => {
                const price = isLow ? d.low.toFixed(2) : d.high.toFixed(2)
                const tagSupp = d.scoreSupport > 0 ? renderToString(<TagSupportTT />) : ''
                const tagResis = d.scoreResistance > 0 ? renderToString(<TagResistanceTT />) : ''
                const first = d.signalFirst ? ' <sup><b>1st</b></sup>' : ''
                const content =
                    'Date: <b>' + d.datetime + '</b>' +
                    '<br />Price: <b>' + price + '</b>' +
                    '<br />Tags: ' + tagResis + tagSupp

                return {
                    name: '<div ' + ttSubTitleStyle + '">' + renderToString(sig) + first + '</div>' + content,
                    // MARK : Distinguish 1st signals
                    itemStyle: {
                        color: d.signalFirst ? color : 'rgb(255, 255, 255, 0)',
                        borderWidth: 1,
                        borderColor: color
                    },
                    emphasis: {
                        itemStyle: {
                            color: color,
                        }
                    },
                    value: [d.datetime, price],
                    tooltip: {
                        show: true,
                        trigger: 'item',
                        formatter: function (params) {
                            return '<div ' + ttContainerStyle + '><div ' + ttHeaderStyle + '>' + renderToString(sig) + first + '</div>' + content
                        }
                    }
                }
            }

            const sigBuyL1 = <><IconSignalBuyL1TT /> &nbsp;<b>Buy Signal L1</b></>
            const sigBuyL2 = <><IconSignalBuyL2TT /> &nbsp;<b>Buy Signal L2</b></>
            const sigSellL1 = <><IconSignalSellL1TT /> &nbsp;<b>Sell Signal L1</b></>
            const sigSellL2 = <><IconSignalSellL2TT /> &nbsp;<b>Sell Signal L2</b></>

            // Last signals in array have superior z-index, thus we concat signal first last so that they display above following signals
            const scatterBuyL1 = dataFiltered.filter(d => d.signalBullBuyL1 !== 'NaN' && d.signalFirst === false).map(d => mapFunc(d, true, sigBuyL1, COLOR_SIGNAL_BUY_L1))
                .concat(dataFiltered.filter(d => d.signalBearBuyL1 !== 'NaN' && d.signalFirst === false).map(d => mapFunc(d, true, sigBuyL1, COLOR_SIGNAL_BUY_L1)))
                .concat(dataFiltered.filter(d => d.signalBullBuyL1 !== 'NaN' && d.signalFirst === true).map(d => mapFunc(d, true, sigBuyL1, COLOR_SIGNAL_BUY_L1)))
                .concat(dataFiltered.filter(d => d.signalBearBuyL1 !== 'NaN' && d.signalFirst === true).map(d => mapFunc(d, true, sigBuyL1, COLOR_SIGNAL_BUY_L1)))

            const scatterBuyL2 = dataFiltered.filter(d => d.signalBullBuyL2 !== 'NaN' && d.signalFirst === false).map(d => mapFunc(d, true, sigBuyL2, COLOR_SIGNAL_BUY_L2))
                .concat(dataFiltered.filter(d => d.signalBearBuyL2 !== 'NaN' && d.signalFirst === false).map(d => mapFunc(d, true, sigBuyL2, COLOR_SIGNAL_BUY_L2)))
                .concat(dataFiltered.filter(d => d.signalBullBuyL2 !== 'NaN' && d.signalFirst === true).map(d => mapFunc(d, true, sigBuyL2, COLOR_SIGNAL_BUY_L2)))
                .concat(dataFiltered.filter(d => d.signalBearBuyL2 !== 'NaN' && d.signalFirst === true).map(d => mapFunc(d, true, sigBuyL2, COLOR_SIGNAL_BUY_L2)))

            const scatterSellL1 = dataFiltered.filter(d => d.signalBullSellL1 !== 'NaN' && d.signalFirst === false).map(d => mapFunc(d, false, sigSellL1, COLOR_SIGNAL_SELL_L1))
                .concat(dataFiltered.filter(d => d.signalBearSellL1 !== 'NaN' && d.signalFirst === false).map(d => mapFunc(d, false, sigSellL1, COLOR_SIGNAL_SELL_L1)))
                .concat(dataFiltered.filter(d => d.signalBullSellL1 !== 'NaN' && d.signalFirst === true).map(d => mapFunc(d, false, sigSellL1, COLOR_SIGNAL_SELL_L1)))
                .concat(dataFiltered.filter(d => d.signalBearSellL1 !== 'NaN' && d.signalFirst === true).map(d => mapFunc(d, false, sigSellL1, COLOR_SIGNAL_SELL_L1)))

            const scatterSellL2 = dataFiltered.filter(d => d.signalBullSellL2 !== 'NaN' && d.signalFirst === false).map(d => mapFunc(d, false, sigSellL2, COLOR_SIGNAL_SELL_L2))
                .concat(dataFiltered.filter(d => d.signalBearSellL2 !== 'NaN' && d.signalFirst === false).map(d => mapFunc(d, false, sigSellL2, COLOR_SIGNAL_SELL_L2)))
                .concat(dataFiltered.filter(d => d.signalBullSellL2 !== 'NaN' && d.signalFirst === true).map(d => mapFunc(d, false, sigSellL2, COLOR_SIGNAL_SELL_L2)))
                .concat(dataFiltered.filter(d => d.signalBearSellL2 !== 'NaN' && d.signalFirst === true).map(d => mapFunc(d, false, sigSellL2, COLOR_SIGNAL_SELL_L2)))

            // VOLATILITY //
            // setSeriesVolatility(dataFiltered.filter(d => d.volatility !== 'NaN').map(d => d.volatility))

            // MAX POINT //
            /*const pointsMax = data.filter(d => d.maxEMADiff !== 0).map(d => (
                {
                    name: 'Mark',
                    symbol: 'triangle',
                    symbolSize: 10,
                    symbolRotate: ((d.emaAboveMA === 'Above') ? 180 : 0),
                    coord: [d.datetime, ((d.emaAboveMA === 'Above') ? d.high.toFixed(2) * 1.04 : d.low.toFixed(2) * 0.96)],
                    //value: d.maxEMADiff,
                    itemStyle: {
                        color: 'grey',
                        opacity: 0.5
                    }
                }))*/


            // TRADING BOOK //
            // TODO : Save in browser DB..., remove entry when trading book is updated for the specific ticker

            let scatterBuyOrder = []
            let scatterSellOrder = []
            if (!props.isDemo && props.interval == '1d') {

                // Compute orders with splits dates, all orders before each splits are divided by the split value
                const ordersOfTicker = userContext.stockTradingBook.filter(entry => entry.symbol === currentTicker)
                const updSplitsTradingBook = ordersOfTicker.map(order => {
                    let newOrder = Object.create(order)

                    for (const splitDate in infos.splits) {
                        if (newOrder.date.split("T")[0] < splitDate) {
                            newOrder.price /= infos.splits[splitDate];
                        }
                    }

                    return newOrder;
                })

                scatterBuyOrder = updSplitsTradingBook.filter(entry => entry.actionType === 'BUY').map(entry => createBuyOrder(entry))
                scatterSellOrder = updSplitsTradingBook.filter(entry => entry.actionType === 'SELL').map(entry => createSellOrder(entry))
            }


            // CHART OPTIONS //

            const commonXAxisConfig = {
                type: 'category',
                data: timestamp,
                boundaryGap: false,
                splitLine: { show: false },
                axisLabel: { show: false },
                axisTick: { show: false },
                axisLine: { show: false },
                min: 0 - CHART_DAYS_OFFSET,
            }

            const commonYAxisConfig = {
                show: false,
                axisPointer: {
                    show: false,
                }
            }

            setOptions({
                legend: {
                    top: 'auto',
                    [IS_MOBILE ? 'left' : 'right']: 'auto',
                    data: ['Buy Signal L1', 'Buy Signal L2', 'Take Profit L1', 'Take Profit L2', 'Sell Signal L1', 'Sell Signal L2'],
                    itemGap: 15,
                    itemWidth: 11,
                    // Custom tooltip for legend items
                    tooltip: {
                        show: true,
                        position: 'top',
                        formatter: function (obj) {
                            switch (obj.name) {
                                case 'Buy Signal L1':
                                    return renderToString(BuySignalDesc);
                                case 'Buy Signal L2':
                                    return renderToString(BuySignalDesc);
                                case 'Take Profit L1':
                                    return renderToString(TakeProfitDesc);
                                case 'Take Profit L2':
                                    return renderToString(TakeProfitDesc);
                                case 'Sell Signal L1':
                                    return renderToString(SellSignalDesc);
                                case 'Sell Signal L2':
                                    return renderToString(SellSignalDesc);
                                default:
                                    return obj.name;
                            }
                        }
                    }
                },
                tooltip: {
                    // Not working
                    // className: 'echarts-tooltip',
                    triggerOn: 'mousemove',
                    trigger: 'axis',
                    hideDelay: 500,
                    axisPointer: {
                        type: "cross"
                    },
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    borderWidth: 0,
                    extraCssText: 'padding: 0.7rem; min-width: 240px;',
                    textStyle: {
                        color: '#fff',
                        fontSize: 12,
                    },
                    position: function (pos, params, el, elRect, size) {
                        // el.style.boxShadow = "none";
                        const obj = {
                            top: '0%',
                            left: '0%',
                        };
                        return obj;
                    },

                    // MARK : Candle TT formatter
                    formatter: function (params) {
                        let html = '<div ' + ttContainerStyle + '><div>'

                        // To see what is available for tooltip data
                        // console.debug(JSON.stringify(params))

                        // Add candle data
                        let candle = params.find(item => item.seriesName === 'Candle')
                        if (candle != null && Object.hasOwn(candle, 'value') && candle.value.length >= 4 && candle.data.silent !== true) {
                            html = html + '<div ' + ttHeaderStyle + '>Date: ' + candle.axisValueLabel + '</div>'
                            html = html + '<b>O</b>' + candle.value[1] + ' • <b>H</b>' + candle.value[4] + ' • <b>L</b>' + candle.value[3] + ' • <b>C</b>' + candle.value[2];
                        } else {
                            return null     // Case cursor on no candle area > candle.data.silent !== true
                        }

                        // Add volume data
                        let volume = params.find(item => item.seriesName === 'Volume')
                        if (volume) {
                            html = html + '<br />Volume: <b>' + formatNumberIn(volume.value) + '</b></div>';
                        }

                        // Add take profit
                        if (candle.data.groupID === 'signalFirstAboveMA' || candle.data.groupID === 'signalFirstAboveEMA') {
                            html = html + candle.data.name
                        }

                        // Add signals tags
                        const idxBSL1 = params.find(item => item.seriesName === 'Buy Signal L1');
                        if (idxBSL1) {
                            html = html + idxBSL1.name
                        }
                        const idxBSL2 = params.find(item => item.seriesName === 'Buy Signal L2');
                        if (idxBSL2) {
                            html = html + idxBSL2.name
                        }
                        const idxSSL1 = params.find(item => item.seriesName === 'Sell Signal L1');
                        if (idxSSL1) {
                            html = html + idxSSL1.name
                        }
                        const idxSSL2 = params.find(item => item.seriesName === 'Sell Signal L2');
                        if (idxSSL2) {
                            html = html + idxSSL2.name
                        }

                        // Add buy and sell orders infos
                        const buyOrders = params.filter(item => item.seriesName === 'Buy Order');
                        if (buyOrders.length > 0) {
                            buyOrders.forEach(order => { html = html + order.name })
                        }
                        const sellOrders = params.filter(item => item.seriesName === 'Sell Order');
                        if (sellOrders.length > 0) {
                            sellOrders.forEach(order => { html = html + order.name })
                        }

                        return html + '</div>'
                    }
                },
                axisPointer: {
                    link: [
                        {
                            xAxisIndex: [0, 1, 2, 3]
                        }
                    ]
                },
                toolbox: {
                    show: false
                },
                brush: {
                    toolbox: ['rect', ''],
                    throttleDelay: 400,
                    seriesIndex: 0,
                    brushStyle: {
                        borderWidth: 0,
                        color: 'rgba(254,155,20,0.1)',
                    },
                    outOfBrush: {
                        colorAlpha: 0.1,
                        opacity: 0.2
                    }
                },
                dataZoom: [
                    {
                        type: IS_MOBILE && CANDLE_SLIDER_ENABLE ? 'slider' : 'inside',
                        ...sliderZoomStyle,
                        // Zoom is smoother with 50 than 100
                        throttle: CHART_ZOOM_THROTTLE,
                        xAxisIndex: [0, 1, 2, 3],
                        startValue: timestamp[timestamp.length - CHART_DAYS_OFFSET - CHART_ZOOM_DEFAULT_HISTORY],
                        endValue: timestamp[timestamp.length - CHART_DAYS_OFFSET + CHART_ZOOM_DEFAULT_OFFSET],
                        minValueSpan: CHART_ZOOM_MIN_SPAN,
                        maxValueSpan: CHART_ZOOM_MAX_SPAN
                    }
                ],
                grid: [
                    // Candles
                    {
                        top: CHART_POSITION_TOP,
                        bottom: arkTrades.length > 0 ? 80 : 40,
                        left: CHART_POSITION_LEFT,
                        right: chartPositionRight,
                        height: 'auto'
                    },
                    // Volume
                    {
                        bottom: arkTrades.length > 0 ? 80 : 40,
                        left: CHART_POSITION_LEFT,
                        right: chartPositionRight,
                        height: CHART_VOL_HEIGHT
                    },
                    // Earnings & Dividends
                    {
                        bottom: arkTrades.length > 0 ? 88 : 48,
                        left: CHART_POSITION_LEFT,
                        right: chartPositionRight,
                        height: CHART_VOL_HEIGHT
                    },
                    // Ark Trades
                    {
                        bottom: 20,
                        left: CHART_POSITION_LEFT,
                        right: chartPositionRight,
                        height: 20
                    }
                ],
                xAxis: [
                    {
                        type: 'category',
                        data: timestamp,
                        boundaryGap: false,
                        axisLine: { show: false },
                        axisTick: { show: false },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: YAXIS_LINE_STYLE
                            }
                        },
                        axisLabel: {
                            formatter: (value, index) => {
                                return formatDateToBetterReading(value);
                            }
                        },
                        min: 0 - CHART_DAYS_OFFSET,
                        max: 'dataMax',
                        axisPointer: {
                            ...axisPointerStyle,
                            type: 'shadow'
                        }
                    },
                    {
                        // axisPointer "show" property should be set to true for TT data
                        gridIndex: 1,
                        axisPointer: { show: true, type: 'none' },
                        max: 'dataMax',
                        ...commonXAxisConfig
                    },
                    {
                        gridIndex: 2,
                        axisPointer: { show: false, type: 'none' },
                        max: timestamp[timestamp.size],
                        ...commonXAxisConfig
                    },
                    {
                        gridIndex: 3,
                        axisPointer: {
                            type: 'shadow',
                            label: {
                                show: false
                            }
                        },
                        max: timestamp[timestamp.size],
                        ...commonXAxisConfig
                    },
                ],
                yAxis: [
                    {
                        name: quote.currency,
                        nameTextStyle: {
                            align: 'left',
                            padding: [0, 0, 0, chartYAxisLabelLeftPadding]
                        },
                        nameGap: Y_AXIS_NAME_GAP,
                        axisLabel: {
                            showMinLabel: false,
                            formatter: function (value, index) {
                                return value.toFixed(IS_MOBILE ? 1 : 2)
                            }
                        },
                        scale: true,
                        position: 'right',
                        // For margin at the bottom of the lowest visible candle
                        min: function (value) {
                            const res = value.min - (value.max - value.min) * 0.15
                            if (res < 0)
                                return 0
                            else
                                return res
                        },
                        axisLine: { show: false },
                        splitLine: {
                            lineStyle: {
                                color: YAXIS_LINE_STYLE
                            }
                        },
                        splitNumber: 9,
                        axisTick: { show: false },
                        axisPointer: axisPointerStyle,

                        // type: 'log',
                        // max: function (value) {
                        //     return value.max + value.max * 0.05;
                        // },
                        // logBase: 2
                    },
                    {
                        gridIndex: 1,
                        ...commonYAxisConfig
                    },
                    {
                        gridIndex: 2,
                        ...commonYAxisConfig
                    },
                    {
                        gridIndex: 3,
                        ...commonYAxisConfig
                    }
                ],


                // CHART SERIES ---------------------------------------------------------------------------------------------------------------------

                series: [
                    {
                        type: 'candlestick',
                        name: 'Candle',
                        data: seriesCandle,
                        itemStyle: candleStyle,
                        markLine: {
                            symbol: ['none', 'none'],
                            data: candleSwitch ? seriesTrendLocal : [],
                        },
                        selectedMode: true,
                        select: {
                            itemStyle: CANDLE_SELECTED
                        },
                        z: 10
                    },
                    {
                        name: 'Take Profit L2',
                        type: 'line',
                        data: seriesMA50,
                        smooth: true,
                        symbol: "line",     // For symbol use in the legend
                        showSymbol: false,
                        color: '#2478B7',
                        lineStyle: {
                            width: 1
                        }
                    },
                    {
                        name: 'Take Profit L1',
                        type: 'line',
                        data: seriesEMA30,
                        smooth: true,
                        symbol: "line",     // For symbol use in the legend
                        showSymbol: false,
                        color: '#f64d2a',
                        lineStyle: {
                            width: 1
                        }
                    },
                    {
                        name: 'Buy Signal L1',
                        ...signalsCommonConfigs,
                        color: COLOR_SIGNAL_BUY_L1,
                        data: scatterBuyL1,
                        animationDelay: 0,
                        z: 2
                    },
                    {
                        name: 'Buy Signal L2',
                        ...signalsCommonConfigs,
                        color: COLOR_SIGNAL_BUY_L2,
                        data: scatterBuyL2,
                        animationDelay: 0,
                        z: 1
                    },
                    {
                        name: 'Sell Signal L1',
                        ...signalsCommonConfigs,
                        color: COLOR_SIGNAL_SELL_L1,
                        data: scatterSellL1,
                        animationDelay: CHART_ANIM_SIGNAL_DURATION,
                        z: 1
                    },
                    {
                        name: 'Sell Signal L2',
                        ...signalsCommonConfigs,
                        color: COLOR_SIGNAL_SELL_L2,
                        data: scatterSellL2,
                        animationDelay: CHART_ANIM_SIGNAL_DURATION,
                        z: 2
                    },
                    {
                        name: 'Volume',
                        type: 'bar',
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: VOL_STYLE_LIGHT,
                        data: seriesVol
                    },
                    /*{
                        name: 'Volatility',
                        type: 'line',
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        data: seriesVolatility,
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            width: 1
                        }
                    },*/
                    {
                        name: 'Earnings & Dividends',
                        type: 'bar',
                        xAxisIndex: 2,
                        yAxisIndex: 2,
                        markPoint: {
                            data: earnings.concat(dividends)
                        },
                    },
                    {
                        name: 'Price Line',
                        type: 'scatter',
                        data: seriesRealTime,
                        markLine: {
                            animation: true,
                            symbol: ['none', 'none'],
                            data: priceLine
                        }
                    },
                    {
                        name: 'Buy Order',
                        data: scatterBuyOrder,
                        symbol: 'path://' + svgBuyOrder,
                        color: ACTION_TYPE_BUY_COLOR,
                        ...orderCommonConfigs
                    },
                    {
                        name: 'Sell Order',
                        data: scatterSellOrder,
                        symbol: 'path://' + svgSellOrder,
                        color: ACTION_TYPE_SELL_COLOR,
                        ...orderCommonConfigs
                    },
                    {
                        name: 'Ark Trades',
                        type: 'scatter',
                        data: arkTrades,
                        xAxisIndex: 3,
                        yAxisIndex: 3,
                        symbolSize: 12,
                        symbolKeepAspect: true,
                        emphasis: {
                            scale: 1.6,
                        }
                    }
                ],

                jti_idxLastDayHisto: idxLastDayHisto
            })
            setLoading(false)
        }
        fetchData()

        // With only props the component re-render after pin item to the watchlist
    }, [props.ticker, props.interval, props.tkrInfos, props.reloadKey])

    // 1 - Run first to init echarts instance and dispose
    useEffect(() => {

        var chart = null;
        if (chartRef.current !== null) {        // Set by first return with no useEffect
            chart = init(chartRef.current);
            loading === true ? chart.showLoading(CHART_LOADING_OPTS) : chart.hideLoading()
        }

        // Add chart resize listener
        function resizeChart() {
            chart?.resize();
        }
        window.addEventListener("resize", resizeChart);

        // Return cleanup function
        return () => {
            chart?.dispose();
            window.removeEventListener("resize", resizeChart);
        }
    }, [loading])

    // 2 - Whenever theme changes we need to add option and setting due to it being deleted in cleanup function
    useEffect(() => {

        // Update chart
        if (chartRef.current !== null) {

            const chart = getInstanceByDom(chartRef.current);
            chart.setOption(options);

            // BRUSH CONFIG //

            chart.on('brushSelected', function (params) {
                // console.log(params);

                const selectedRanges = params.batch[0]?.selected[0];
                const areas = params.batch[0]?.areas;

                if (chartRef.current !== null && selectedRanges != undefined && areas != undefined &&
                    areas.length > 0 &&
                    areas[0].range.length == 2 &&
                    selectedRanges.dataIndex.length > 0) {

                    const chart = getInstanceByDom(chartRef.current);
                    const option = chart.getOption()

                    var candleFirstIdx = selectedRanges.dataIndex[0];
                    var candleLastIdx = selectedRanges.dataIndex[selectedRanges.dataIndex.length - 1];
                    if (candleLastIdx > option.jti_idxLastDayHisto && candleFirstIdx > option.jti_idxLastDayHisto)
                        return
                    if (candleLastIdx > option.jti_idxLastDayHisto)
                        candleLastIdx = option.jti_idxLastDayHisto
                    if (candleFirstIdx > option.jti_idxLastDayHisto)
                        candleFirstIdx = option.jti_idxLastDayHisto

                    // console.log(option);
                    // console.log(params);
                    // console.log('First idx: ' + candleFirstIdx);
                    // console.log('Last idx: ' + candleLastIdx);

                    const nbCandles = candleLastIdx - candleFirstIdx + 1
                    const candleFirstLow = parseFloat(option.series[0].data[candleFirstIdx].value[2])
                    const candleFirstHigh = parseFloat(option.series[0].data[candleFirstIdx].value[3])
                    const candleLastLow = parseFloat(option.series[0].data[candleLastIdx].value[2])
                    const candleLastHigh = parseFloat(option.series[0].data[candleLastIdx].value[3])

                    // console.log('candleFirstLow: ' + candleFirstLow);
                    // console.log('candleFirstHigh: ' + candleFirstHigh);
                    // console.log('candleLastLow: ' + candleLastLow);
                    // console.log('candleLastHigh: ' + candleLastHigh);

                    let prctChange = 0
                    if (candleFirstLow < candleLastHigh)
                        prctChange = (candleLastHigh - candleFirstLow) / candleFirstLow * 100
                    else if (candleFirstLow > candleLastHigh)
                        prctChange = (candleLastLow - candleFirstHigh) / candleFirstHigh * 100

                    chart.setOption({
                        graphic: [
                            {
                                type: 'group',
                                id: 'tt',
                                x: areas[0].range[0][0],
                                y: areas[0].range[1][1] + 10,
                                children: [
                                    {
                                        type: 'rect',
                                        invisible: false,
                                        shape: {
                                            width: 169,
                                            height: 55,
                                            r: 4
                                        },
                                        style: {
                                            fill: 'rgba(254,155,20,1)',
                                            shadowBlur: 8,
                                            shadowOffsetX: 3,
                                            shadowOffsetY: 3,
                                            shadowColor: 'rgba(0,0,0,0.2)'
                                        },
                                        z: 100
                                    },
                                    {
                                        type: 'text',
                                        x: 11,
                                        y: 10,
                                        style: {
                                            fill: '#fff',
                                            // font: '1em "STHeiti", sans-serif',
                                            text: 'Frist to last candle: ' + prctChange.toFixed(2) + '%\n\n'
                                                + nbCandles + ' Candles',
                                        },
                                        z: 100
                                    }
                                ]
                            }
                        ],
                    })
                }
            })

            chart.on('brushEnd', function (params) {

                if (chartRef.current !== null && params.areas.length == 0) {
                    // console.log(params);

                    const chart = getInstanceByDom(chartRef.current);
                    chart.setOption({
                        graphic: [
                            {
                                type: 'group',
                                id: 'tt',
                                children: [
                                    {
                                        type: 'rect',
                                        invisible: true
                                    },
                                    {
                                        type: 'text',
                                        style: {
                                            text: ''
                                        }
                                    }
                                ]
                            }
                        ]
                    })
                }
            })


            // CONTEXT MENU //

            /*// Variable to track if context menu is open
            var contextMenuOpen = false;
            var menuCount = 0;
    
            // Attach contextmenu event for showing the delete option
            chart.getZr().on('click', function (params) {
                // Prevent default right-click behavior
                params.event.preventDefault();
    
                // Get mouse position
                var clientX = params.event.offsetX;
                var clientY = params.event.offsetY;
    
                var mouseX = params.event.clientX;
                var mouseY = params.event.clientY;
    
                console.log(params);
                var xAxisValue = chart.convertFromPixel({ seriesIndex: 0 }, [clientX, clientY])[0];
                console.log(options.xAxis[0].data[xAxisValue]);
    
                // Create and show context menu based on menu count
                var menu;
                if (contextMenuOpen) {
                    // Remove the previous menu
                    var prevMenu = document.getElementById('context-menu-' + (menuCount - 1));
                    prevMenu.remove();
                    menuCount--;
                    // Set contextMenuOpen to true
                    contextMenuOpen = false;
                    // Toggle between the first and second menus
                    // menu = document.createElement('div');
                    // menu.innerHTML = '<div id="context-menu-' + menuCount + '" class="chart-context-menu" style="position: fixed; top: ' + mouseY + 'px; left: ' + mouseX + 'px; background-color: white; border: 1px solid black; padding: 5px;">Second Context Menu</div>';
                } else {
                    // Show the first menu
                    menu = document.createElement('div');
                    menu.innerHTML = '<div id="context-menu-' + menuCount + '" style="position: fixed; top: ' + mouseY + 'px; left: ' + mouseX + 'px; background-color: white; border: 1px solid black; padding: 5px;">First Context Menu</div>';
                    // Append the menu to the body
                    document.body.appendChild(menu);
    
                    // Set contextMenuOpen to true
                    contextMenuOpen = true;
    
                    // Increment menu count
                    menuCount++;
                }
    
                // Add event listener for click event to remove context menu on click
                document.addEventListener('contextmenu', function (event) {
                    console.log(1);
                    console.log(event);
                    if (contextMenuOpen) {
                        var lastMenu = document.getElementById('context-menu-' + (menuCount - 1));
                        lastMenu.remove();
                        contextMenuOpen = false;
                        menuCount--;
                    }
                })
            })*/


            // EARNINGS AGI POPUP //

            let popupCount = 0
            let timeout

            // Event listener for mouse hover on markpoint
            chart.on('mouseover', { name: 'Earnings AGI' }, function (params) {
                // console.log(params);

                // Get the position of the chart container
                let chartContainer = chart.getDom()
                let chartRect = chartContainer.getBoundingClientRect()

                // Convert xAxis value to pixel
                let xAxisIndex = chart.convertToPixel({ gridIndex: 2 }, [params.data.coord[0], 0])
                let coordX = chartRect.left + xAxisIndex[0]
                let coordY = chartRect.top + xAxisIndex[1] - 40

                if (popupCount === 0) {
                    showPopup(coordY, coordX, params.data.coord[0])
                    timeout = setTimeout(hidePopup, 1000)
                    popupCount++
                }
            })

            // Function to show popup menu
            function showPopup(posTop, posLeft, earningDate) {
                var popup = document.createElement('div')
                popup.className = 'agi-popup'
                popup.style.position = 'fixed'
                popup.style.top = posTop + 'px'
                popup.style.left = posLeft + 'px'
                // Above modal
                popup.style.zIndex = 1001
                document.body.appendChild(popup)

                let icon = <Image src={getLogoUrl(currentTicker)} width={35} preview={false} />
                let title = props.tkrInfos?.shortName + ' - Earnings Summary (' + earningDate + ')'
                let html = agi['earnings_' + earningDate].response

                createRoot(popup).render(
                    <ConfigProvider theme={ANTD_THEME_TOKEN}>
                        <Button type="primary" size='small' shape="square" onClick={() => showEarningsAGIModal(icon, title, html)}>AI</Button>
                    </ConfigProvider>
                )

                popup.addEventListener('mouseover', function () {
                    // Cancel the popup timeout
                    clearTimeout(timeout)
                })

                popup.addEventListener('mouseout', function () {
                    // Extend popup visibility
                    timeout = setTimeout(hidePopup, 1000)
                })
            }

            // Function to hide popup menu
            function hidePopup() {
                var popup = document.querySelector('.agi-popup')
                if (popup) {
                    popup.parentNode.removeChild(popup)
                    popupCount--
                }
            }


            // HIGHTLIGHT HOVER ARK TRADES SCATTERS //

            chart.on('mouseover', { seriesName: 'Ark Trades' }, function (params) {
                // console.log(params)

                var tradeDate = params.value[0];
                var candlestickSeries = chart.getOption().xAxis[1].data;
                var highlightIndex = -1;

                // Find the corresponding candlestick
                for (var i = 0; i < candlestickSeries.length; i++) {
                    if (candlestickSeries[i] === tradeDate) {
                        highlightIndex = i;
                        break;
                    }
                }

                if (highlightIndex !== -1) {
                    chart.dispatchAction({
                        type: 'select',
                        seriesIndex: 0,
                        dataIndex: highlightIndex
                    })
                }
            })

            chart.on('mouseout', { seriesName: 'Ark Trades' }, function (params) {

                var tradeDate = params.value[0];
                var candlestickSeries = chart.getOption().xAxis[1].data;
                var highlightIndex = -1;

                // Find the corresponding candlestick
                for (var i = 0; i < candlestickSeries.length; i++) {
                    if (candlestickSeries[i] === tradeDate) {
                        highlightIndex = i
                        break;
                    }
                }

                if (highlightIndex !== -1) {
                    chart.dispatchAction({
                        type: 'unselect',
                        seriesIndex: 0,
                        dataIndex: highlightIndex
                    })
                }
            })


            // MARK : REAL TIME //

            if (enableRealTime) {
                const intervalId = setInterval(updateCandle, REAL_TIME_INTERVAL)

                // Clear interval on component unmount
                return () => clearInterval(intervalId)
            }
        }

    }, [options])

    return (<>
        <div id='ai-chart' ref={chartRef} style={{ height: chartContainerHeight }} />

        {/* Legend */}
        {/* <div className='chart-legend-box'>
            <Text className='chart-legend-text' italic>AI model last update: {lastUpdate}</Text>
        </div> */}

        <Space style={{ position: 'absolute', top: modal ? '1.3em' : '0.75em', right: IS_MOBILE ? modal ? '4em' : '5.3em' : '7em', color: 'grey' }} size={IS_MOBILE ? 'small' : 'large'}>
            <Space>
                <Tooltip placement='top' color='orange' title={ShowSuppResis} mouseEnterDelay={TOOLTIP_ENTER_DELAY}>
                    <Button type="text" size='small' style={{ color: stateBtnSuppResis ? COLOR_PRIMARY : 'grey' }} icon={<VerticalAlignMiddleOutlined />} onClick={toggleSuppResis} />
                </Tooltip>
                {!IS_MOBILE &&
                    <Tooltip placement='top' color='orange' title={Measure} mouseEnterDelay={TOOLTIP_ENTER_DELAY}>
                        <Button type="text" size='small' style={{ color: stateBtnBrush ? COLOR_PRIMARY : 'grey' }} icon={<GatewayOutlined />} onClick={toggleBrush} />
                    </Tooltip>
                }
                <Tooltip placement='top' color='orange' title={Reset} mouseEnterDelay={TOOLTIP_ENTER_DELAY}>
                    <Button type="text" size='small' style={{ color: 'grey' }} icon={<ReloadOutlined />} onClick={toggleZoomReset} />
                </Tooltip>
            </Space>
            <Space>Light <Switch checked={chartStyleSwitch} onChange={updChartMode} size='small' style={{ marginBottom: '0.2em' }} /> Pro</Space>
        </Space>

        {haveFundTrades && <>

            {/* Horizontal line */}
            <div style={{ position: 'absolute', bottom: '50px', right: '0em', borderTop: '1px solid #d9d9d9', width: '100%' }}></div>

            {/* ARK logo */}
            <div style={{ position: 'absolute', bottom: '20px', right: IS_MOBILE ? '0.5em' : '1.2em', }}>
                <Tooltip placement='top' color='orange' title={InfoArkInvest}>
                    <Image src='/ark_invest_logo.png' width={IS_MOBILE ? 39 : 44} preview={false} />
                </Tooltip>
            </div>
        </>}

        <ModalAI open={openModalAGI} setOpen={setOpenModalAGI} icon={modalAGIIcon} title={modalAGITitle} text={modalAGIText} />
        {contextHolder}

    </>)
}

export default CandlestickChart;
