import { green } from '@ant-design/colors';
import { Card, Row, Col, Descriptions, Progress } from 'antd';
import React from "react";
import IndexCandle from './charts/IndexCandle';


const IndexCard = (props) => {


    return (
        <Card title={props.title} extra={props.tag} className='card'>
            <Row gutter={[0, 30]}>
                <Col xs={24}>
                    <Descriptions column={4} size="large">
                        <Descriptions.Item label="Avg Time" span={2}>
                            {Number(props.data.cycleBullAvg).toFixed()} day(s)
                        </Descriptions.Item>
                        <Descriptions.Item label="Max Time" span={2}>
                            {props.data.cycleBullMax} day(s)
                        </Descriptions.Item>
                        <Descriptions.Item label="Current Time" span={2}>
                            {props.data.cycleCurrent} day(s)
                        </Descriptions.Item>
                        <Descriptions.Item>
                            <Progress percent={(props.data.cycleCurrent * 100 / props.data.cycleBullMax).toFixed(1)} size="small" strokeColor={green[7]} />
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col xs={24}>
                    <IndexCandle ticker={props.ticker} interval='1d' lastUpdate='xxx' />
                </Col>
            </Row>
        </Card>
    )
}
export default IndexCard;