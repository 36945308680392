import { IconLoading } from './Icons';


const Loading = () => {

    return (<div style={{ height: '100vh', alignItems: 'center', display: 'flex' }}>
        <IconLoading />
    </div>)
}

export default Loading;