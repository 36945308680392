import { Helmet } from 'react-helmet';
import { Layout, Card, Col, Row } from 'antd';
import PageTitle1 from '../components/PageTitle1';
import Meta from 'antd/es/card/Meta';
import { Link } from 'react-router-dom';


const blogPosts = [
    {
        title: "The Swing Resilient Strategy",
        description: "Trading consists of two simple...",
        path: "swingresilientstrategy",
        imgFile: "swingresilientstrategy.jpg"
    },
    {
        title: "Trading Checklist",
        description: "Before buying any asset...",
        path: "tradingchecklist",
        imgFile: "tradingchecklist.jpg"
    }
]

const Blog = () => {
    return (
        <Layout className="site-layout-content">
            <Helmet>
                <title>Just Trade It: Blog</title>
                <meta name="description" content="Explore insightful articles and tutorials on trading to enhance your knowledge and skills." />
            </Helmet>

            <PageTitle1> • Blog</PageTitle1>

            <Row gutter={[{ xs: 10, sm: 15, md: 40 }, 0]}>
                {blogPosts.map((post, index) => (
                    <Col xs={{ span: 12 }} md={{ span: 6 }} key={index}>
                        <Link to={`/blog/${post.path}`}>
                            <Card className='card' bordered hoverable
                                cover={<img alt={post.title} src={`/blog/${post.imgFile}`} />}>
                                <Meta title={post.title} description={post.description} />
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </Layout>
    );
};

export default Blog;