import { MinusOutlined } from '@ant-design/icons';
import { Anchor } from 'antd';


const handleClick = (e, link) => {
  e.preventDefault()
}


const JTIAnchor = ({ items, ...props }) => {

  const updatedAnchorMenu = items.map(item => ({
    ...item,
    title: <MinusOutlined />,
  }))


  return (
    <Anchor className='anchor-menu' offsetTop={250} onClick={handleClick} items={updatedAnchorMenu} {...props} />
  )
}

export default JTIAnchor;