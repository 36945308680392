import { StockOutlined } from '@ant-design/icons';
import { Result, Typography} from 'antd';

const { Text } = Typography

const NoData = (props) => {
  return (
    <Result style={{ margin: 'auto', opacity: 0.4, ...props.style }} className={props.className} icon={<StockOutlined style={{ color: 'grey' }} />}
      title={<Text style={{ fontSize: '1em'}}>No data</Text>} />
  )
}

export default NoData;