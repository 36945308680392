import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;


export const getTickerDemoInfos = async (symbol) => {
    const config = {
        url: `${apiServerUrl}/demo/ticker/${symbol}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    }
    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    }
}

export const getTickerDemoEarnings = async (symbol) => {
    const config = {
        url: `${apiServerUrl}/demo/ticker/${symbol}/earnings`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    }
    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    }
}

export const getTickerDemoHistory = async (symbol, interval) => {
    const config = {
        url: `${apiServerUrl}/demo/ticker/${symbol}/${interval}`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    }
    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    }
}

export const getTickerDemoQuote = async (symbol) => {
    const config = {
        url: `${apiServerUrl}/demo/ticker/${symbol}/quote`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    }
}