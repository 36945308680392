import { TeamOutlined, UserOutlined, LineChartOutlined } from '@ant-design/icons';
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Col, Layout, Row, Statistic, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { IconLoading } from '../../components/Icons';
import UserContext from '../../components/UserContext';
import { getAdminMetrics } from '../../services/jti.service';
import LineChart from "../../components/charts/LineChart";
import PageTitle1 from '../../components/PageTitle1';

const { Text } = Typography;


const AdminHome = () => {

    const { getAccessTokenSilently } = useAuth0();
    const { userContext } = useContext(UserContext);

    const [nbUser, setNbUser] = useState('');

    const [tkrViewsSeries, setTkrViewsSeries] = useState([]);
    const [tkrViewsXAxis, setTkrViewsXAxis] = useState([]);
    const [tkrViewsLastDate, setTkrViewsLastDate] = useState('');
    const [tkrViewsLastData, setTkrViewsLastData] = useState('');

    const [uniqueUsersSeries, setUniqueUsersSeries] = useState([]);
    const [uniqueUsersXAxis, setUniqueUsersXAxis] = useState([]);
    const [uniqueUsersLastDate, setUniqueUsersLastDate] = useState('');
    const [uniqueUsersLastData, setUniqueUsersLastData] = useState('');


    useEffect(() => {

        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently()
            const { data, error } = await getAdminMetrics(accessToken)

            setNbUser(data.nbUser)

            let tvSeries = []
            let tvXAxis = []
            data.tkrViewsPerDay.map(metric => {
                tvXAxis.push(metric.date)
                tvSeries.push(metric.count)
            })
            setTkrViewsXAxis(tvXAxis)
            setTkrViewsSeries([
                {
                    name: 'Symbol Views',
                    data: tvSeries
                },
            ])
            setTkrViewsLastDate(tvXAxis[tvXAxis.length - 1])
            setTkrViewsLastData(tvSeries[tvSeries.length - 1])

            let uuSeries = []
            let uuXAxis = []
            data.uniqueUserPerDay.map(metric => {
                uuXAxis.push(metric.date)
                uuSeries.push(metric.count)
            })
            setUniqueUsersXAxis(uuXAxis)
            setUniqueUsersSeries([
                {
                    name: 'Unique Users',
                    data: uuSeries
                },
            ])
            setUniqueUsersLastDate(uuXAxis[uuXAxis.length - 1])
            setUniqueUsersLastData(uuSeries[uuSeries.length - 1])
        }

        // Avoid two API calls
        if (userContext)
            fetchData()

    }, [userContext])

    // userContext needs to be setted otherwise error
    if (userContext == null) {
        return (
            <Layout className="site-layout-content">
                <IconLoading />
            </Layout>)
    }

    return (<>
        <PageTitle1> • Admin</PageTitle1>

        <Row align='middle' gutter={[{ xs: 10, sm: 10, md: 30 }, 0]}>
            <Col >
                <Card className='card'>
                    <Statistic title={<><TeamOutlined /> Users</>} value={nbUser} />
                </Card>
            </Col>
            <Col>
                <Card className='card'>
                    <Statistic title={<><UserOutlined /> Unique Users</>} value={uniqueUsersLastData} suffix={<Text style={{ fontSize: '0.5em', color: 'grey' }}>({uniqueUsersLastDate})</Text>} />
                </Card>
            </Col>
            <Col>
                <Card className='card'>
                    <Statistic title={<><LineChartOutlined /> Symbol Views</>} value={tkrViewsLastData} suffix={<Text style={{ fontSize: '0.5em', color: 'grey' }}>({tkrViewsLastDate})</Text>} />
                </Card>
            </Col>
            <Col span={24}>
                <LineChart title='Symbol Views Per Day' series={tkrViewsSeries} xAxis={tkrViewsXAxis} color={2} />
            </Col>
            <Col span={24}>
                <LineChart title='Unique User Per Day' series={uniqueUsersSeries} xAxis={uniqueUsersXAxis} color={0} />
            </Col>
        </Row>
    </>)
}

export default AdminHome;