import '../styles/Blog.css'
import { Typography } from 'antd';
const { Title } = Typography;


const PageTitle1 = ({children, ...props }) => {

    return <Title className='blog-title1' level={1} {...props} >{children}</Title>
}

export default PageTitle1;