import React, { useState } from 'react';
import { Descriptions, Input, Select, DatePicker, Button, Form } from 'antd';
import dayjs from 'dayjs';

const { Option } = Select;

const Test = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  
  // Initial values
  const [data, setData] = useState({
    name: 'John Doe',
    gender: 'male',
    birthdate: dayjs('1990-01-01'),
    occupation: 'Engineer',
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        setData({
          ...data,
          ...values,
          birthdate: values.birthdate ? dayjs(values.birthdate) : data.birthdate, // Convert date to dayjs
        });
        setIsEditing(false);
      })
      .catch((error) => {
        console.log('Validation failed:', error);
      });
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <>
      <Descriptions title="User Information" bordered>
        <Descriptions.Item label="Name">
          {isEditing ? (
            <Form.Item name="name" initialValue={data.name} rules={[{ required: true }]}>
              <Input placeholder="Enter your name" />
            </Form.Item>
          ) : (
            data.name
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Gender">
          {isEditing ? (
            <Form.Item name="gender" initialValue={data.gender} rules={[{ required: true }]}>
              <Select>
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
            </Form.Item>
          ) : (
            data.gender === 'male' ? 'Male' : 'Female'
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Birthdate">
          {isEditing ? (
            <Form.Item name="birthdate" initialValue={data.birthdate}>
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
          ) : (
            data.birthdate.format('YYYY-MM-DD')
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Occupation">
          {isEditing ? (
            <Form.Item name="occupation" initialValue={data.occupation}>
              <Input placeholder="Enter your occupation" />
            </Form.Item>
          ) : (
            data.occupation
          )}
        </Descriptions.Item>
      </Descriptions>

      {isEditing ? (
        <>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
          <Button style={{ marginLeft: '10px' }} onClick={handleCancel}>
            Cancel
          </Button>
        </>
      ) : (
        <Button type="primary" onClick={handleEdit}>
          Edit
        </Button>
      )}
    </>
  );
};

export default Test;