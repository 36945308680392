
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Card, Col, Layout, Pagination, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { IconLoading } from '../../components/Icons';
import PageTitle1 from '../../components/PageTitle1';
import PostCard from '../../components/admin/PostCard';
import { getPostAll } from '../../services/community.service';



// PARAMETERS //



const Community = () => {

    const [isLoading, setIsLoading] = useState(true)
    const { getAccessTokenSilently } = useAuth0()
    const [postsTable, setPostsTable] = useState([])
    const [isSortedAscending, setIsSortedAscending] = useState(false);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1)
    const pageSize = 8;

    // Calculate current data based on page
    const startIndex = (currentPage - 1) * pageSize
    const currentData = postsTable.slice(startIndex, startIndex + pageSize)

    // Pagination handler
    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    // Sort handler
    const toggleSort = () => {
        const sortedData = [...postsTable].sort((a, b) => {
            const compareField = 'created'
            if (isSortedAscending) {
                return new Date(b[compareField]) - new Date(a[compareField]);
            } else {
                return new Date(a[compareField]) - new Date(b[compareField]);
            }
        })

        setIsSortedAscending(!isSortedAscending)
        setPostsTable(sortedData)
    }

    // Fetch data from the API
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)

            const accessToken = await getAccessTokenSilently()
            const resp = await getPostAll(accessToken)

            setPostsTable(resp.data)

            setIsLoading(false)
        };

        fetchData();
    }, []); // Empty dependency array means this effect runs once on mount


    // RETURN //

    if (isLoading) {
        return (
            <Layout className="site-layout-content">
                <IconLoading />
            </Layout>)
    }

    return (<>
        <PageTitle1> • Posts</PageTitle1>

        <Card className='card'>

            {/* Filters */}
            <div style={{ textAlign: 'right', marginBottom: '2em' }}>
                <Button onClick={toggleSort}>
                    Sort by Date {isSortedAscending ? '▼' : '▲'}
                </Button>
            </div>

            <Row gutter={[16, 16]}>
                {currentData.map((post, index) => (
                    <Col xs={24} sm={12} md={6} key={index}>
                        <PostCard post={post} />
                    </Col>
                ))}
            </Row>

            <div style={{ textAlign: 'center', marginTop: '1em' }}>
                <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={postsTable.length}
                    onChange={handlePageChange}
                />
            </div>
        </Card>
    </>);
};

export default Community