import { CaretRightOutlined } from '@ant-design/icons';
import { Card, Collapse } from 'antd';
import { useEffect, useState } from "react";
import { isMobile } from '../utils/utils';


const IS_MOBILE = isMobile()


const MenuCollapse = ({ items, enable = true, ...props }) => {

    const [menuActiveKey, setActiveKey] = useState(1);

    const togglePanel = () => {
        setActiveKey(prevActiveKey => {
            return prevActiveKey === 1 ? 0 : 1
        })
    }

    // Add 'onClick' attribute to each object in the array
    const finalItems = items.map((item, index) => ({
        key: index + 1,
        ...item,
        showArrow: enable
    }))

    // Run only once
    useEffect(() => {

        if (!enable)
            return

        IS_MOBILE ? setActiveKey(0) : setActiveKey(1)
    }, [])

    return (
        <Card className='card'>
            <Collapse className='menu-collapse' items={finalItems} activeKey={menuActiveKey} onChange={enable ? togglePanel : () => { }}
                expandIconPosition='end'
                collapsible='header'
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                ghost
                {...props} />
        </Card>
    )
}

export default MenuCollapse;