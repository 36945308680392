import { IconSignalBuyL1, IconSignalBuyL2, IconSignalSellL1, IconSignalSellL2, IconSignalOff, IconEarning, IconVolume, IconMA, IconEMA, IconBearFlag, IconBullFlag } from '../components/Icons';
import { Layout, Typography, List, Col, Row, Space, Divider } from 'antd';
import { Disclaimer, InfoAIEfficiency, InfoBuySignal, InfoDivYield, InfoExDivDate, InfoSellSignal, InfoShortRatio, InfoTagResilient, InfoTagYoung } from '../components/TempLang';

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getTickerList } from "../services/ticker.service";
import { TagAIAverage, TagClassResilient, TagAIGood, TagAIHigh, TagAILow, TagClassYoung } from '../components/Tags';
import { Helmet } from 'react-helmet';
import PageTitle1 from '../components/PageTitle1';

const { Title, Paragraph, Text, Link } = Typography;

const data = [
    ['1.0', '15/9/2023', 'Initial Version'],
    ['1.1', '23/9/2023', 'Website is now responsive for mobile'],
    ['1.2', '26/9/2023', 'Add the Screener page'],
    ['1.3', '30/9/2023', 'Add the Demo page'],
    ['1.4', '11/10/2023', 'Add AI model efficiency on the Chart Analysis page'],
    ['1.5', '25/10/2023', 'Add Trade Checklist page, add AI model efficiency tags'],
    ['1.6', '31/10/2023', 'Add signal email alert, add account page'],
    ['1.7', '8/11/2023', 'Add Resilient tag for stocks, improve screener filter, update Trade Checklist, update Trading Strategy Memo'],
    ['1.8', '29/11/2023', 'Add real-time daily chart, trading profile, Take Profit + alert, update Trade Checklist, add chart Light/Pro view, add candle select tool'],
    ['1.9', '18/12/2023', 'Add real-time on watchlist, add take profit signals'],
    ['1.10', '11/1/2024', 'Hightligh take profit on candlestick charts, add portfolio feature, improve tagging'],
    ['1.11', '7/4/2024', 'AI status in portfolio, AI earnings summary, earnings calendar'],
    ['1.12', '...', 'More to come...']
]


const AIAnalysisDocu = () => {

    const { getAccessTokenSilently } = useAuth0();
    const [tickerList, setTickerList] = useState("");
    const [nbTkr, setNbTkr] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const formattedStocks = [];
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await getTickerList(accessToken);

            for (const symbol in data) {
                const companyName = data[symbol];
                const formattedEntry = `${companyName} (${symbol})`;
                formattedStocks.push(formattedEntry);
            }

            setNbTkr(formattedStocks.length)
            setTickerList(formattedStocks.join(", "))
        }
        fetchData();
    }, [])

    const rowStyle = { padding: '10px 10px' }
    const rowGutt = [4, 4]
    const titleSize = 3
    const col1Span = { span: 3 }
    const col2Span = { span: 21 }

    return (
        <Layout className="site-layout-content-fixed">
            <Helmet>
                <title>Just Trade It: AI Analysis Reference</title>
            </Helmet>

            <PageTitle1> • JTI AI Analysis</PageTitle1>

            <br />
            <Paragraph>
                Welcome to Just Trade It!<br /><br />
                Although the <b>JTI AI Model</b> is performing plenty calculations for you, we also encourage users to acquire <b>basic knowledge of technical chart analysis</b>.
                This will help them to better understand how the AI model works. Below are some informations regarding the AI Model.
            </Paragraph>

            <Title level={titleSize}>Our AI Model</Title>
            <Paragraph>
                JTI AI Model correlate multiple parameters by analysing assets behavior's from it's firt day, as well as macro and environment indicators. The parameters include market trends, volatility, supports and resistances among others.
                It’s important to note that our model is not predicting the future movement of the asset, but rather the best moment to make gains, according to the history data.
                Furthermore, market analysis is not an exact science, and predicting the market is like predicting the future. Our model reflects our way of approaching the market in the most secure manner.
            </Paragraph>

            <Title level={titleSize}>Tags, Icons And Lexicon</Title>
            <Paragraph>
                <Title level={5}><span className='ai-logo'>AI</span> Tags And Icons</Title>
                <Row gutter={rowGutt} style={rowStyle} justify='center'>
                    <Col align='middle' lg={col1Span}>
                        <TagClassResilient />
                    </Col>
                    <Col lg={col2Span}>
                        <InfoTagResilient />
                    </Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle} justify='center'>
                    <Col align='middle' lg={col1Span}>
                        <TagClassYoung />
                    </Col>
                    <Col lg={col2Span}>
                        <InfoTagYoung />
                    </Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle} justify='center'>
                    <Col align='middle' lg={col1Span}>
                        <Space direction='vertical'>
                            <TagAIHigh />
                            <TagAIGood />
                            <TagAIAverage />
                            <TagAILow />
                        </Space>
                    </Col>
                    <Col lg={col2Span}>
                        <InfoAIEfficiency />
                    </Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col align='middle' lg={col1Span}><Space size="middle"><span><IconSignalOff /> L1</span><span><IconSignalOff /> L2</span></Space></Col>
                    <Col lg={col2Span}>No active signals.</Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col align='middle' lg={col1Span}><Space size="middle"><span><IconSignalBuyL1 /> L1</span><span><IconSignalBuyL2 /> L2</span></Space></Col>
                    <Col lg={col2Span}><InfoBuySignal /></Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col align='middle' lg={col1Span}><Space size="middle"><span><IconSignalSellL1 /> L1</span><span><IconSignalSellL2 /> L2</span></Space></Col>
                    <Col lg={col2Span}><InfoSellSignal /></Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col align='middle' lg={col1Span}><IconEMA /></Col>
                    <Col lg={col2Span}>Take Profit Level 1.</Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col align='middle' lg={col1Span}><IconMA /></Col>
                    <Col lg={col2Span}>Take Profit Level 2.</Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col align='middle' lg={col1Span}><IconVolume /></Col>
                    <Col lg={col2Span}>Indicate the volume of shares sold per day</Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col align='middle' lg={col1Span}><IconEarning /></Col>
                    <Col lg={col2Span}>Indicates the earning date for a company; companies will release their earning data on this date.</Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col align='middle' lg={col1Span}><IconBullFlag /></Col>
                    <Col lg={col2Span}>Flag indicating that the asset is entering a bull market cycle.</Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col align='middle' lg={col1Span}><IconBearFlag /></Col>
                    <Col lg={col2Span}>Flag indicating that the asset is entering a bear market cycle.</Col>
                </Row>
            </Paragraph>
            <Paragraph>
                <Title level={5}>Common Financial Lexicon</Title>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col lg={col1Span}><b>Dividends Yield</b></Col>
                    <Col lg={col2Span}><InfoDivYield /></Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col lg={col1Span}><b>Ex-Dividend Date</b></Col>
                    <Col lg={col2Span}><InfoExDivDate /></Col>
                </Row>
                <Row gutter={rowGutt} style={rowStyle}>
                    <Col lg={col1Span}><b>Short Ratio</b></Col>
                    <Col lg={col2Span}><InfoShortRatio /></Col>
                </Row>
            </Paragraph>

            <Title level={titleSize}>Stock Symbol List</Title>
            <Paragraph>
                Here are all the {nbTkr} symbols analyzed with our AI model:
            </Paragraph>
            <Paragraph>
                {tickerList}, more is coming...
            </Paragraph>

            <Title level={titleSize}>Application Release Versions</Title>
            <Paragraph>
                Here are all our released versions :
                <List style={{ marginTop: '5px' }} bordered dataSource={data}
                    renderItem={(item) => (
                        <List.Item>
                            {item[1]} - <Text mark>v{item[0]}</Text>: {item[2]}
                        </List.Item>
                    )}
                />
            </Paragraph>

            <br />
            <Divider />
            <Title level={5} style={{ marginTop: '0' }}><i>Disclaimer</i></Title>
            <Paragraph><Disclaimer /></Paragraph>
        </Layout >
    )
}

export default AIAnalysisDocu;