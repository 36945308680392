
// DOC : https://auth0.com/docs/authenticate/login/auth0-universal-login/configure-default-login-routes
const Login = () => {

    const auth0TenantAuth = 'https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/authorize'

    window.location.href = auth0TenantAuth
 
    return
}

export default Login;