import { MenuOutlined, MoreOutlined } from '@ant-design/icons';
import { Link, useLocation } from "react-router-dom";
import { Menu, Drawer, Button } from 'antd';
import React, { useContext, useState } from 'react';
import { LoginButton } from "./buttons/login-button";
import { SignupButton } from "./buttons/signup-button";
import { LogoutButton } from "./buttons/logout-button";
import { useAuth0 } from "@auth0/auth0-react";
import { isMobile } from '../utils/utils';
import { isAdmin } from '../services/authorization.service';
import UserContext from '../components/UserContext';
import TickerSearchBox from './TickerSearchBox';

const { Item } = Menu


const Navigation = () => {

    const { userContext } = useContext(UserContext);
    const location = useLocation();
    const { isAuthenticated } = useAuth0();

    const [drawerVisible, setDrawerVisible] = useState(false);

    const showDrawer = () => {
        setDrawerVisible(true);
    }
    const closeDrawer = () => {
        setDrawerVisible(false);
    }

    const JTISubMenuStyle = !isMobile() ? { paddingLeft: '1em' } : {}
    const JTISubMenu = () => (<>
        <Item key="/account" style={JTISubMenuStyle}>
            <Link to="/account" onClick={closeDrawer}>Account</Link>
        </Item>
        <Item key="/pricing" style={JTISubMenuStyle}>
            <Link to="/pricing" onClick={closeDrawer}>Pricing</Link>
        </Item>
        {isAdmin(userContext) &&
            <Item key="/admin" style={JTISubMenuStyle}>
                <Link to="/admin" onClick={closeDrawer}>Admin</Link>
            </Item>
        }
        <Item key="/logout" style={JTISubMenuStyle}>
            <LogoutButton />
        </Item>
    </>)

    const selectedMenu = '/' + location.pathname.split('/')[1]
    const JTIMenu = () => (
        <Menu className={isMobile() ? 'navigation-mobile' : 'navigation'} theme="dark" mode={isMobile() ? 'vertical' : 'horizontal'} selectedKeys={selectedMenu}>

            {isAuthenticated && (<>
                <Item key="/screener">
                    <Link to="/screener" onClick={closeDrawer}>AI Screener</Link>
                </Item>
                <Item key="/tickeranalysis">
                    <Link to="/tickeranalysis" onClick={closeDrawer}>AI Charts</Link>
                </Item>
                <Item key="/watchlist">
                    <Link to="/watchlist" onClick={closeDrawer}>Watchlist</Link>
                </Item>
                <Item key="/portfolio">
                    <Link to="/portfolio" onClick={closeDrawer}>Portfolio</Link>
                </Item>
                <Item key="/macroview">
                    <Link to="/macroview" onClick={closeDrawer}>Macro View</Link>
                </Item>
                <Item key="/blog">
                    <Link to="/blog">Blog</Link>
                </Item>
                {!isMobile() ? (
                    <Menu.SubMenu key="more" icon={<MoreOutlined style={{ fontSize: '1.5em' }} />}>
                        <JTISubMenu />
                    </Menu.SubMenu>
                ) : (
                    <JTISubMenu />
                )}
            </>)}

            {!isAuthenticated && (<>
                <Item key="/demo">
                    <Link to="/demo" onClick={closeDrawer}>Demo</Link>
                </Item>
                <Item key="/pricing">
                    <Link to="/pricing" onClick={closeDrawer}>Pricing</Link>
                </Item>
                <Item key="/blog">
                    <Link to="/blog">Blog</Link>
                </Item>
                {!isMobile() &&
                    <Item key="/signup" style={{ paddingLeft: "10px", }}>
                        <SignupButton className='button-signup' label='Sign Up Free' />
                    </Item>
                }
                <Item key="/login">
                    <LoginButton />
                </Item>
            </>)}
        </Menu>
    )

    return (<>
        {isMobile() ? (
            <>
                <div className="button-navigation">
                    {!isAuthenticated ? (
                        <SignupButton className='button-signup' label='Sign Up Free' />
                    ) : (
                        <TickerSearchBox size='middle' style={{ width: '9rem' }} />
                    )}
                    <Button style={{ margin: '0 0 0 15px' }} type="primary" onClick={showDrawer} icon={<MenuOutlined />} />
                </div>
                <Drawer bodyStyle={{ padding: '0px' }} placement="left" closable={false} onClose={closeDrawer} open={drawerVisible} width={200}>
                    <JTIMenu />
                </Drawer>
            </>
        ) : (<>
            <JTIMenu />
            {(location.pathname !== '/newuserstartup') && isAuthenticated &&
                <div style={{ float: 'right' }}>
                    <TickerSearchBox size='middle' style={{ width: '13rem', height: '1.7rem', margin: '24px 1rem 0 0 ' }} />
                    <span style={{ width: '1px', height: '22px', borderRight: '1px solid grey', margin: '27px 18px 0 0px' }} />
                </div>}
        </>)}
    </>)
}
export default Navigation;