import * as echarts from "echarts/core";
import { volcano, blue } from '@ant-design/colors';
import { theme } from 'antd';
import { isMobile } from './utils/utils';

const { defaultAlgorithm, darkAlgorithm } = theme;

// TODO : Use constant for IS_MOBILE
// const IS_MOBILE = isMobile()

// GENERAL //

export const TOOLTIP_ENTER_DELAY = 0.8

export const ASSET_TYPE_INDEX = 'INDEX'
export const ASSET_TYPE_ETF = 'ETF'
export const ASSET_TYPE_STOCK = 'STOCK'
export const ASSET_TYPE_CRYPTO = 'CRYPTO'

// CHARTS //

export const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL
export const CANDLE_HISTO_DEFAULT = process.env.REACT_APP_CANDLE_HISTO_DEFAULT
export const LOGO_64_URL = process.env.REACT_APP_LOGO_64_URL
export const CHART_INDEX_CANDLE_HISTORY = 60

// LOCAL STORAGE //

export const STORE_SCREENER_FILTER_ASSET_TYPE = 'STORE_SCREENER_FILTER_ASSET_TYPE'
export const STORE_SCREENER_FILTER_BSL1 = 'STORE_SCREENER_FILTER_BSL1'
export const STORE_SCREENER_FILTER_BSL2 = 'STORE_SCREENER_FILTER_BSL2'
export const STORE_SCREENER_FILTER_SSL1 = 'STORE_SCREENER_FILTER_SSL1'
export const STORE_SCREENER_FILTER_SSL2 = 'STORE_SCREENER_FILTER_SSL2'
export const STORE_SCREENER_FILTER_TAG_RESILIENT = 'STORE_SCREENER_FILTER_TAG_RESILIENT'
export const STORE_SCREENER_FILTER_TAG_AI_HIGH = 'STORE_SCREENER_FILTER_TAG_AI_HIGH'
export const STORE_SCREENER_FILTER_TAG_AI_GOOD = 'STORE_SCREENER_FILTER_TAG_AI_GOOD'
export const STORE_SCREENER_FILTER_TAG_AI_AVG = 'STORE_SCREENER_FILTER_TAG_AI_AVG'
export const STORE_SCREENER_FILTER_TAG_AI_LOW = 'STORE_SCREENER_FILTER_TAG_AI_LOW'
export const STORE_SCREENER_FILTER_INDUSTRY = 'STORE_SCREENER_FILTER_INDUSTRY'
export const STORE_SCREENER_FILTER_EXCHANGE = 'STORE_SCREENER_FILTER_EXCHANGE'

// COLORS //

export const COLOR_PRIMARY = '#F05454'
export const COLOR_SIGNAL_BUY_L1 = blue[4]
export const COLOR_SIGNAL_BUY_L2 = blue[7]
export const COLOR_SIGNAL_SELL_L1 = volcano[3]
export const COLOR_SIGNAL_SELL_L2 = volcano[7]
export const ACTION_TYPE_BUY_COLOR = blue[6]
export const ACTION_TYPE_SELL_COLOR = volcano[6]

// https://stackoverflow.com/questions/76839686/how-does-the-api-for-echarts-lineargradient-work
export const CHART_COLOR_STYLES = [

    // Blue
    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            // Blue 7 #0958D9
            color: 'rgb(8, 151, 156, 0.9)',
        },
        {
            offset: 1,
            // Cyan 7 #08979c
            color: 'rgb(9, 88, 217, 0.9)',
        }
    ]),

    // Green
    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            // Gold 6 #FAAD14
            color: 'rgb(250, 173, 20 ,0.9)'
        },
        {
            offset: 1,
            // Green 8 #237804
            color: 'rgb(35, 120, 4 ,0.9)'
        }
    ]),

    // Orange
    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            // Volcano 5 #FF7A45
            color: 'rgb(255, 122, 69, 0.9)',
        },
        {
            offset: 1,
            // Red 8 #CF1322
            color: 'rgb(168, 7, 26, 0.9)',

        }
    ]),

    // Pink
    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            // Magenta 6 #EB2F96
            color: 'rgb(235, 47, 150, 0.9)'
        },
        {
            offset: 1,
            // Purple 7 #531dab
            color: 'rgb(83, 29, 171, 0.9)'
        }
    ])
]

// MARK AntD theme: configProvider for the entire App
export const ANTD_THEME_TOKEN = {
    token: {
        fontSizeHeading1: 36,

        //algorithm: defaultAlgorithm,
        colorPrimary: COLOR_PRIMARY,
        colorBgLayout: '#FFFFFF',
        colorBgContainer: '#FFFFFF',

        colorFillAlter: 'rgba(240, 240, 240, 0.4)',          // MARK Description label background color
        colorText: 'rgba(0, 0, 0, 1)',
        borderRadius: 4,
        colorLink: volcano[7],
        colorLinkHover: COLOR_PRIMARY,
        colorLinkActive: COLOR_PRIMARY,
        // colorBorderSecondary: '#d9d9d9',
        // paddingLG: IS_MOBILE ? 12 : 24
    },
    components: {
        Menu: {
            darkPopupBg: '#000',
        },
        Result: {
            iconFontSize: 45
        },
        Table: {
            headerSplitColor: '#d9d9d9',
        }
    },
}

// DATE //

export const DATE_YEAR_FIRST = 'YYYY-MM-DD'
export const DATE_DAY_FIRST = 'DD-MM-YYYY'

// DATA //

// Trading profiles
export const tradingProfileOptions = [
    { value: 'DCA', label: 'Dollar Cost Averaging (DCA)' },
    { value: 'SWING', label: 'Swing Trader' },
    { value: 'ACTIVE', label: 'Active Trader' },
]

// Post status
export const postStatusOptions = [
    { value: 'SCHEDULED', label: 'SCHEDULED' },
    { value: 'CANCELED', label: 'CANCELED' },
    { value: 'HOLD', label: 'HOLD' },
    { value: 'PUBLISHED', label: 'PUBLISHED' }
]

export const AssetTypeOptions = [
    { label: 'All', value: 'ALL' },
    { label: 'Stocks', value: ASSET_TYPE_STOCK },
    { label: 'ETFs', value: ASSET_TYPE_ETF },
    { label: 'Cryptocurrencies', value: ASSET_TYPE_CRYPTO },
];


// LOGGING //

export const LOG_HIGHLIGHT_STYLE = "color:green;"