import '../../styles/Blog.css'
import React from 'react';
import { Layout, Typography, Divider, Breadcrumb } from 'antd';
import { isMobile } from '../../utils/utils';
import JTIAnchor from '../../components/JTIAnchor';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import BlogTitle1 from '../../components/BlogTitle1';
import PageTitle2 from '../../components/PageTitle2';
import BlogPostNavigationBottom from '../../components/BlogPostNavigationBottom';


// PARAMETERS //

const IS_MOBILE = isMobile()

// SHARED VARIABLES //

const { Text, Paragraph } = Typography;
const anchorMenu = [
    {
        key: 'introduction',
        href: '#introduction',
    },
    {
        key: 'quick-start',
        href: '#quick-start',
    },
    {
        key: 'assets',
        href: '#assets',
    },
    {
        key: 'analyze',
        href: '#analyze',
    },
    {
        key: 'watchlist',
        href: '#watchlist',
    },
    {
        key: 'mindset',
        href: '#mindset',
    },
]

const youtubeProps = {
    height: IS_MOBILE ? '260' : '515',
    width: '100%',
    allowfullscreen: 'allowfullscreen',
    style: {
        border: 'none',
    }
}


const Tutorial = () => {

    return (<>
        <Helmet>
            <title>Just Trade It: Tutorial The Swing Resilient Strategy</title>
            <meta name="description" content="Trading consists of two simple actions: buy, then sell. The challenge lies in executing these actions at the right time! This is where Just Trade It can assist you..." />
        </Helmet>

        {!isMobile() &&
            <JTIAnchor items={anchorMenu} />
        }

        <Layout className="site-layout-content-fixed site-layout-content-blog">

            <BlogTitle1 id="introduction"> • The Swing Resilient Strategy</BlogTitle1>

            <div className='tuto-theme-container'>
                <Paragraph style={{ marginBottom: '6em' }}>
                    <blockquote>
                        <Text className='mentra'>
                            "Trading consists of two simple actions: buy, then sell. The challenge lies in executing these actions at the right time!<br />
                            This is where Just Trade It can assist you..."
                        </Text>
                    </blockquote>
                </Paragraph>
                <Paragraph className='blog-paragraph'>
                    <b>Just Trade It</b> is an AI based platform that enables users to find the right asset for investment and the right time to invest in them.
                    Currently, JTI provides only the most relevant market information for investing and does not  facilitate order execution.
                    To execute trades, you will need to register with a broker such as Wealthsimple or Robinhood.
                </Paragraph>
                <Paragraph className='blog-paragraph'>
                    This tutorial will teach you how to execute what we call <b>"The Swing Resilient Strategy"</b>.<br />
                    Here is the short video that explain how the strategy works:
                    <div style={{ textAlign: 'center', marginTop: '2em' }}>
                        <iframe src="https://www.youtube.com/embed/0SghP9BFf3k" {...youtubeProps} />
                    </div>
                </Paragraph>
            </div>

            <Divider className='blog-divider'>QUICK START</Divider>

            <div id="quick-start" className='tuto-theme-container'>
                <Paragraph className='blog-paragraph'>
                    To start your journey with <b>"The Swing Resilient Strategy"</b>, we will follow these steps:
                    <ol style={{ margin: '15px 0 0 30px' }}>
                        <li>Find the <b>resilients</b> assets</li>
                        <li>Analyze the asset with the <b>AI Chart</b></li>
                        <li>Add the asset to your <b>watchlist</b></li>
                        {/* <li>Place an order in your portfolio</li> */}
                        <li>Adopt the <b>mindset</b> of investors</li>
                    </ol>
                </Paragraph>
            </div>

            <div id="assets" className='tuto-theme-container'>
                <PageTitle2>Find The Resilients Assets</PageTitle2>
                <div style={{ textAlign: 'center' }}>
                    <iframe src="https://www.youtube.com/embed/0KQHRebcuPA" {...youtubeProps} />
                </div>
            </div>

            <div id="analyze" className='tuto-theme-container'>
                <PageTitle2>Analyze The Asset With The AI Chart</PageTitle2>
                <div style={{ textAlign: 'center' }}>
                    <iframe src="https://www.youtube.com/embed/YU6pX-gXMQU" {...youtubeProps} />
                </div>
            </div>

            <div id="watchlist" className='tuto-theme-container'>
                <PageTitle2>Add The Asset To Your Watchlist</PageTitle2>
                <div style={{ textAlign: 'center' }}>
                    <iframe src="https://www.youtube.com/embed/fk6XVqTzAQE" {...youtubeProps} />
                </div>
            </div>

            <div id="mindset" className='tuto-theme-container'>
                <PageTitle2>The Investor Mindset</PageTitle2>

                <Paragraph className='blog-paragraph'>
                    At Just Trade It, we believe that mindset plays a crucial role in becoming a successful investor, especially in the long term.<br />
                    That's why we have published a <Link to="/blog/tradechecklist" >Trade Checklist</Link> that we recommend reading each time you consider executing an order.
                    This will help wire your brain with the fundamentals of investment as a reflex.
                </Paragraph>
            </div>

            <BlogPostNavigationBottom />
        </Layout>
    </>)
}

export default Tutorial;