import { DeleteOutlined, EllipsisOutlined, InstagramOutlined, TikTokOutlined, XOutlined, FacebookOutlined, RedditOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Card, Descriptions, Image, Popconfirm, Select, message, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { postStatusOptions } from '../../JTIConst';
import { updatePost, deletePost } from '../../services/community.service';
import { printDateTime } from '../../utils/utils';
import styles from '../../styles/admin/PostCard.module.css';

const { Paragraph } = Typography;
const { Meta } = Card;
const { Item } = Descriptions;

const PostCard = ({ post }) => {

    const { getAccessTokenSilently } = useAuth0()
    const [text, setText] = useState()
    const [statusStyle, setStatusStyle] = useState()
    const [xPosting, setXPosting] = useState()
    const [facebookPosting, setFacebookPosting] = useState()
    const [instagramPosting, setInstagramPosting] = useState()
    const [redditPosting, setRedditPosting] = useState()
    const [tiktokPosting, setTiktokPosting] = useState()


    const callUpdatePost = async (post) => {
        const accessToken = await getAccessTokenSilently()
        await updatePost(accessToken, post.id, post)
        message.success('Post updated!');
    }

    const handleEditText = async (newText) => {
        if (newText !== post.text) {
            setText(newText)
            post.text = newText
            callUpdatePost(post)
        }
    }

    const handleSwitchX = async () => {
        const newValue = !post.x
        post.x = newValue
        setXPosting(newValue)
        callUpdatePost(post)
    }

    const handleSwitchFacebook = async () => {
        const newValue = !post.facebook
        post.facebook = newValue
        setFacebookPosting(newValue)
        callUpdatePost(post)
    }

    const handleSwitchInstagram = async () => {
        const newValue = !post.instagram
        post.instagram = newValue
        setInstagramPosting(newValue)
        callUpdatePost(post)
    }

    const handleSwitchReddit = async () => {
        const newValue = !post.reddit
        post.reddit = newValue
        setRedditPosting(newValue)
        callUpdatePost(post)
    }

    const handleSwitchTiktok = async () => {
        const newValue = !post.tiktok
        post.tiktok = newValue
        setTiktokPosting(newValue)
        callUpdatePost(post)
    }

    const handleStatusChange = async (newStatus) => {
        post.status = newStatus
        callUpdatePost(post)
        setStatusStyle(['CANCELED', 'PUBLISHED'].includes(newStatus) ? styles['custom-select'] : null)
    }

    const handleDelete = async () => {
        const accessToken = await getAccessTokenSilently()
        await deletePost(accessToken, post.id)
        message.success('Post deleted!')
        window.location.reload()
    }

    const actions = [
        <XOutlined key="x" onClick={handleSwitchX} style={{ color: xPosting ? 'red' : 'grey' }} />,
        <FacebookOutlined key="facebook" onClick={handleSwitchFacebook} style={{ color: facebookPosting ? 'red' : 'grey' }} />,
        <InstagramOutlined key="instagram" onClick={handleSwitchInstagram} style={{ color: instagramPosting ? 'red' : 'grey' }} />,
        <RedditOutlined key="reddit" onClick={handleSwitchReddit} style={{ color: redditPosting ? 'red' : 'grey' }} />,
        <TikTokOutlined key="tiktok" onClick={handleSwitchTiktok} style={{ color: tiktokPosting ? 'red' : 'grey' }} />,
        <Popconfirm title="Confirm delete?" onConfirm={handleDelete}>
            <DeleteOutlined key='delete' />
        </Popconfirm>,
        <EllipsisOutlined key="edit" onClick={{}} />,
    ]

    useEffect(() => {
        // Set values here and not in useState() because React use closures and stale state within the useState hook.
        setText(post.text)
        setXPosting(post.x)
        setFacebookPosting(post.facebook)
        setInstagramPosting(post.instagram)
        setRedditPosting(post.reddit)
        setTiktokPosting(post.tiktok)
        setStatusStyle(['CANCELED', 'PUBLISHED'].includes(post.status) ? styles['custom-select'] : null)
    }, [post]);

    return (
        <Card
            key={post.id}
            bordered
            className="card"
            hoverable
            actions={actions}
            cover={<Image alt={post.title} src={post.imageUrlSq} />}>
            <Meta
                title={post.title}
                description={
                    <Descriptions column={1} size="small">
                        <Item label="Text">
                            <Paragraph editable={{ onChange: handleEditText }} style={{ whiteSpace: 'pre-wrap' }}>
                                {text}
                            </Paragraph>
                        </Item>
                        <Item label="created">{printDateTime(post.created)}</Item>
                        <Item label="schedule">{printDateTime(post.schedule)}</Item>
                        <Item label="Status">
                            <Select
                                className={statusStyle}
                                defaultValue={post.status} // Set the default value to the post's current status
                                onChange={(newStatus) => handleStatusChange(newStatus)}
                                options={postStatusOptions} // Available status options
                            />
                        </Item>
                    </Descriptions>
                } />
        </Card>
    );
};

export default PostCard;