import { Descriptions, Flex } from 'antd';
import { displayData, formatNumberToB, formatPourcent, getAITag, isMobile } from '../utils/utils';
import { IconInfoDivYield, IconInfoExDivDate, IconInfoShortRatio, IconWarnShortRatio } from './Icons';
import { TagClassResilient, TagClassYoung } from './Tags';

const { Item, } = Descriptions;


const TickerInfoDescETF = ({ tkrInfos, efficiency, ...props }) => {

    // Define tags
    const tagResilient = tkrInfos.tags?.resilient ? <TagClassResilient /> : ''
    const tagYoung = tkrInfos.tags?.young ? <TagClassYoung /> : ''
    const modelEfficiencyTag = getAITag(efficiency.total_win_percent)

    return (
        <Descriptions column={{ xs: 1, sm: 1, md: 2, lg: 2 }} size={isMobile() ? 'small' : 'default'}>
            <Item label="Fund Family" span={1}>{displayData(tkrInfos.fundFamily)}</Item>
            <Item label="Tags" span={2}><Flex gap="1em 0" wrap>{modelEfficiencyTag}{tagResilient}{tagYoung}</Flex></Item>
            <Item label="Industry">{displayData(tkrInfos.industry)}</Item>
            <Item label="Exchange">{tkrInfos.exchange}</Item>
            <Item label="Total Assets">{formatNumberToB(tkrInfos.totalAssets)}</Item>
            <Item label={<>Dividends Yield<IconInfoDivYield /></>}>{displayData(tkrInfos.dividendYield) === '-' ? '-' : formatPourcent(tkrInfos.dividendYield)}</Item>
            <Item label={<>Ex-Dividend Date<IconInfoExDivDate /></>}>{displayData(tkrInfos.exDividendDate)}</Item>
            <Item label={<>Short Ratio<IconInfoShortRatio /></>}>
                {displayData(tkrInfos.shortRatio) === '-' ? '-' :
                    tkrInfos.shortRatio < 4 ? (
                        <>{tkrInfos.shortRatio}%</>
                    ) : (
                        // lineHeight: '0.8em' > Hack to make the icon sup working
                        <span style={{ lineHeight: '0.8em' }}>{tkrInfos.shortRatio}%<IconWarnShortRatio /></span>
                    )}
            </Item>
        </Descriptions>
    )
}

export default TickerInfoDescETF;