import { PlaySquareOutlined, EditOutlined, InfoOutlined, QuestionOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import { useContext, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import UserContext from '../components/UserContext';
import { mailto } from '../utils/utils';
import ModalFeedback from './modals/ModalFeedback';


const FloatMenu = () => {
    const { setTourOpenChartAnalysis } = useContext(UserContext);
    const navigate = useNavigate()
    const location = useLocation()
    const [openModalFeeback, setOpenModalFeebac] = useState(false);


    return (<>
        <ModalFeedback open={openModalFeeback} setOpen={setOpenModalFeebac} />
        <FloatButton.Group trigger="click" type="primary" className="float-button" icon={<QuestionOutlined />}>
            <FloatButton icon={<EditOutlined />} onClick={() => setOpenModalFeebac(true)} tooltip='Write Review / Request' />
            <FloatButton icon={<InfoOutlined />} onClick={() => navigate('/aianalysisdocu')} tooltip='AI Model Informations' />
            {location.pathname?.startsWith('/tickeranalysis') &&
                <FloatButton icon={<PlaySquareOutlined />} onClick={() => setTourOpenChartAnalysis(true)} tooltip='Replay Starting Tour' />
            }
        </FloatButton.Group>
    </>
    )
}

export default FloatMenu;