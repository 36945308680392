
import { Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Navigation from '../components/Navigation.jsx';
import JTIFooter from "../components/JTIFooter.jsx";
import { Typography, Layout } from 'antd';
import NewsNotifications from "../components/NewsNotifications.jsx";
import FloatMenu from '../components/FloatMenu.jsx';

const { Link } = Typography
const { Header, } = Layout

const AppLayout = () => {
    const { isLoading, isAuthenticated } = useAuth0()

    // MARK : Check Auth0 loading before component rendering (maybe this logic can be moved to UserProvider.js)
    // TODO : Not sure to be used
    if (isLoading) {
        return;
    }

    return (
        <Layout className="site-layout">

            <NewsNotifications />
            
            <Header className="header">
                <Link href="/"><img className="logo" src="/logo_b.png" /></Link>
                <Navigation />
            </Header>
            
            <Outlet />
            
            {   // Display float button only if user is authenticated
                isAuthenticated && <FloatMenu />
            }
            
            <JTIFooter />
            
        </Layout>
    )
}

export default AppLayout;