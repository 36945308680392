import { InfoCircleOutlined } from '@ant-design/icons';
import { ConsiderUpgrade } from './TempLang';
import { COLOR_PRIMARY } from '../JTIConst';


// PARAMETERS //

const DURATION = 300
const PLACEMENT_DEFAULT = 'topRight'


export const NotifUpgradePlan = (message, placement = PLACEMENT_DEFAULT) => {
    return {
        message: 'Account Limit Reached',
        description:
            <>
                {message}<br />
                {ConsiderUpgrade}
            </>,
        placement,
        duration: DURATION,
        icon: (<InfoCircleOutlined style={{ color: COLOR_PRIMARY, }} />)
    }
}