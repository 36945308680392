import { Layout } from 'antd';
import Arrival1 from './pages/Arrival1';


// SHARED VARIABLES //


const Landing = () => {

    return (
        <Layout>
            <Arrival1 />
        </Layout>
    )
}

export default Landing;