import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

import { withAuthenticationRequired } from "@auth0/auth0-react";


export const AuthenticationGuard = ({ component }) => {

    const antIcon = (
        <LoadingOutlined style={{ fontSize: 24 }} spin />
    )

    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <Spin indicator={antIcon} />
        ),
    })

    return <Component />
};
