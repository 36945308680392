import { callExternalApi } from "./external-api.service";


const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getJTIParameters = async () => {
	const config = {
		url: `${apiServerUrl}/api/jti/parameters`,
		method: "GET",
		headers: {
			"content-type": "application/json"
		},
	}

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	}
}

export const getAuditTrail = async (accessToken, limit) => {
	const config = {
		url: `${apiServerUrl}/api/jti/audit/${limit}`,
		method: "GET",
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	}

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	}
}

export const getAdminMetrics = async (accessToken) => {
	const config = {
		url: `${apiServerUrl}/api/jti/metric/admin`,
		method: "GET",
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	}

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	}
}