import { Layout } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { createUser } from "../services/user.service";
import TickerSearchBox from '../components/TickerSearchBox';
import UserContext from '../components/UserContext';
import '../styles/NewUserStartup.css';
import Typewriter from 'typewriter-effect';


const NewUserStartup = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { user } = useAuth0()
    const { setUserContext } = useContext(UserContext)

    useEffect(() => {
        const fetchData = async () => {
            
            // Get utm id if user is coming from a marketing campaign
            const utmId = sessionStorage.getItem('utm_id');

            const userJson = {
                email: user.email,
                utmId: utmId
            }

            const accessToken = await getAccessTokenSilently();
            const { data, error } = await createUser(accessToken, userJson);
            
            // TODO: If not new user redirect to ticker analysis page

            setUserContext(data)
        };
        fetchData();
    }, []);

    return (
        <Layout className="new-user-content">
            <div className="text-writer">
                <Typewriter
                    options={{
                        strings: 'SELECT YOUR FIRST COMPANY : ',
                        autoStart: true,
                        loop: false,
                    }} />
                <TickerSearchBox size='large' className='new-user-startup-searchbox' />
            </div>
        </Layout>
    )
}

export default NewUserStartup;