import { callExternalApi } from "./external-api.service";
import { API_SERVER_URL, CANDLE_HISTO_DEFAULT } from "../JTIConst";



export const getTickerInfos = async (accessToken, symbol) => {
  symbol = encodeURIComponent(symbol)

  const config = {
    url: `${API_SERVER_URL}/api/ticker/${symbol}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getTickersInfos = async (accessToken, symbols) => {
  const config = {
    url: `${API_SERVER_URL}/api/ticker/infos`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: { tickers: symbols }
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getTickerQuote = async (accessToken, symbol) => {
  const config = {
    url: `${API_SERVER_URL}/api/ticker/${symbol}/quote`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getTickersQuotes = async (accessToken, symbols) => {
  const config = {
    url: `${API_SERVER_URL}/api/ticker/quotes`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: { tickers: symbols },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getEarnings = async (accessToken) => {
  const config = {
    url: `${API_SERVER_URL}/api/ticker/earnings`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getTickerEarnings = async (accessToken, symbol) => {
  const config = {
    url: `${API_SERVER_URL}/api/ticker/${symbol}/earnings`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getTickerHistory = async (accessToken, symbol, interval, history = CANDLE_HISTO_DEFAULT) => {
  symbol = encodeURIComponent(symbol)

  const config = {
    url: `${API_SERVER_URL}/api/ticker/${symbol}/${interval}/${history}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getTickerList = async (accessToken) => {
  const config = {
    url: `${API_SERVER_URL}/api/ticker/`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getTickerFull = async (accessToken) => {
  const config = {
    url: `${API_SERVER_URL}/api/ticker/full`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};