import { SearchOutlined, } from '@ant-design/icons';
import { Image, Select } from 'antd';
import { useAuth0 } from "@auth0/auth0-react";
import UserContext from '../components/UserContext';
import React, { useContext, useEffect, useState } from "react";
import { getTickerList } from "../services/ticker.service";
import { useNavigate, useLocation } from 'react-router-dom';
import { addTickerToWatchlist } from "../services/user.service";
import { getLogoUrl } from '../utils/utils';


const TickerSearchBox = (props) => {

    const [tickerList, setTickerList] = useState([]);
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const { userContext } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();

    
    // NOTUSED : Auto complete input
    const getPanelValue = (searchText) => {
        var opts = !searchText ? [] : tickerList.filter((item) => {
            return item.value.toUpperCase().includes(searchText.toUpperCase()) || item.label.toUpperCase().includes(searchText.toUpperCase());
        })
        return opts
    }

    const onSelect = async (ticker) => {

        // New user enrollement
        if (location.pathname === '/newuserstartup') {

            // TODO : Bring this up to the UserProvider component
            // Add ticker to the user watchlist
            if (userContext != null) {
                const accessToken = await getAccessTokenSilently();
                await addTickerToWatchlist(accessToken, userContext.id, ticker)
                userContext.watchlist.push({ ticker: ticker, alert: true })   // Push it cause refresh too fast VS db is getting updated
            }
        }

        navigate('/tickeranalysis/' + ticker)
    }

    // Filter to match the user input
    const filterOption = (input, option) =>
        option?.valueLong?.toUpperCase().includes(input.toUpperCase()) || option?.value?.toUpperCase().includes(input.toUpperCase());

    useEffect(() => {
        const fetchData = async () => {

            const accessToken = await getAccessTokenSilently();
            const tickerListResult = await getTickerList(accessToken);

            // Create array
            var arrayTkr = Object.entries(tickerListResult.data).map(([k, v]) => {
                return {
                    value: k,
                    valueLong: v,
                    label: (<><Image width={15} src={getLogoUrl(k)} preview={false} />&nbsp; {v} ({k})</>),
                }
            })

            setTickerList(arrayTkr)
        }

        if (isAuthenticated)
            fetchData()
    }, [userContext]);

    return (
        <Select
            showSearch
            placeholder="Symbol"
            suffixIcon={<SearchOutlined style={{ pointerEvents: 'none', fontSize: '1.4em' }} />}
            options={tickerList}
            optionLabelProp='value'
            onSelect={(ticker) => onSelect(ticker)}
            // onSearch={(text) => setOptions(getPanelValue(text))}
            filterOption={filterOption}
            popupClassName="tickerSearchBoxPopup"
            {...props} />
    )
}

export default TickerSearchBox;
