import { animated, useTrail } from '@react-spring/web';
import { Avatar, Card, Carousel, Col, Image, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SignupButton } from "../../../components/buttons/signup-button";
import { COLOR_PRIMARY } from '../../../JTIConst';
import styles from '../../../styles/landings/pages/Arrival1.module.css';
import { isMobile } from '../../../utils/utils';

const { Text } = Typography
const IS_MOBILE = isMobile()
const { Meta } = Card


// SHARED VARIABLES //

const signUpButtonText = "Start free 30 days trial"


const Arrival1 = () => {

    // SPRING EFFECTS //

    // Home
    const trailHomeTitle = useTrail(3, {
        config: { mass: 5, tension: 2000, friction: 200 },
        opacity: 1,
        y: 0,
        from: { opacity: 0, y: -20, },
    })

    // CAROUSEL //

    const navigate = useNavigate();
    const routeToDemo = () => {
        navigate('/demo')
    }

    const winStyle = { color: '#DD2D2D' }
    const roiStyle = { color: '#389e0d' }
    const carouselData = [
        {
            title: <span className={styles['card-title']}>Tesla</span>,
            extra: "https://companiesmarketcap.com/img/company-logos/64/TSLA.webp",
            content: <div className={styles['card-content']}><span className={styles['card-metric']} style={winStyle}>88%</span> Win Rate <br /><span className={styles['card-metric']} style={roiStyle}>+37%</span> Average ROI</div>
        },
        {
            title: <span className={styles['card-title']}>Microsoft</span>,
            extra: "https://companiesmarketcap.com/img/company-logos/64/MSFT.webp",
            content: <div className={styles['card-content']}><span className={styles['card-metric']} style={winStyle}>91%</span> Win Rate <br /><span className={styles['card-metric']} style={roiStyle}>+20%</span> Average ROI</div>
        },
        {
            title: <span className={styles['card-title']}>Apple</span>,
            extra: "https://companiesmarketcap.com/img/company-logos/64/AAPL.webp",
            content: <div className={styles['card-content']}><span className={styles['card-metric']} style={winStyle}>71%</span> Win Rate <br /><span className={styles['card-metric']} style={roiStyle}>+15%</span> Average ROI</div>
        },
        {
            title: <span className={styles['card-title']}>Coca-Cola</span>,
            extra: "https://companiesmarketcap.com/img/company-logos/64/KO.webp",
            content: <div className={styles['card-content']}><span className={styles['card-metric']} style={winStyle}>79%</span> Win Rate <br /><span className={styles['card-metric']} style={roiStyle}>+7%</span> Average ROI</div>
        },
    ]

    // MOBILE
    var chunkSize = 2
    if (IS_MOBILE)
        chunkSize = 1

    // Group by chuncks
    const chunks = []
    for (let i = 0; i < carouselData.length; i += chunkSize) {
        chunks.push(carouselData.slice(i, i + chunkSize));
    }

    const carousel =
        <Carousel className={styles['carousel']} autoplay effect="fade" dots={false} autoplaySpeed={2500}>
            {chunks.map((chunk, idx1) => (
                <div key={idx1}>
                    <Row gutter={{ md: 30, lg: 45 }}>
                        {chunk.map((item, idx2) => (
                            <Col key={idx2} xs={24} sm={24} md={24} lg={12}>
                                <Card size='small' className='card' style={{ margin: '6px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)' }} onClick={routeToDemo}>
                                    <Meta style={{ padding: '0.5em', textAlign: 'left' }}
                                        avatar={<Avatar src={item.extra} />}
                                        title={item.title}
                                        description={item.content}
                                    />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}
        </Carousel>

    const signUpButton =
        <div>
            <SignupButton className={styles['signup']} type='primary' label={signUpButtonText} />
        </div>

    return (
        <div className={styles['container']}>
            <div className={styles['content']}>
                <Row gutter={{ sm: 0, md: 50 }} justify='center'>
                    <Col sm={24} md={11} className={styles['block-left']}>
                        <div className={styles['block-left-content']}>
                            <div className={styles['title']}>
                                <animated.div style={trailHomeTitle[0]}>Trading Excellence</animated.div>
                                <animated.div style={trailHomeTitle[2]}>
                                    Made <span style={{ color: COLOR_PRIMARY }}>Simple</span> With <span style={{ fontSize: '0.95em', border: '2px solid ' + COLOR_PRIMARY, color: COLOR_PRIMARY, padding: '0px 11px' }}>AI</span>
                                </animated.div>
                            </div>

                            {!IS_MOBILE && <>
                                <div style={{ width: '7rem', borderBottom: '1px solid #bfbfbf' }} />
                                <Text className={styles['page-description']}>
                                    Revolutionize your trading experience with our cutting-edge AI-powered platform.
                                    Whether you're just starting out or you're a seasoned trader, our app empowers you to make smarter, faster, and more profitable decisions with ease.
                                    Say goodbye to guesswork and hello to effortless trading.
                                </Text>
                            </>}

                            {!IS_MOBILE && signUpButton}
                        </div>

                        {/* Caroussel */}
                        {!IS_MOBILE && carousel}
                    </Col>
                    <Col sm={24} md={13}>
                        <Image id={styles['candle']} src='/landing_laptop.png' alt='Candlestick Chart AI Signals' preview={false} />
                        {IS_MOBILE && carousel}
                        {IS_MOBILE && signUpButton}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Arrival1;