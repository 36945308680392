import { callExternalApi } from "./external-api.service";


const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const isAllowedToViewTkr = async (accessToken, ticker) => {

	const config = {
		url: `${apiServerUrl}/api/authorization/isAllowedToViewTkr/${ticker}`,
		method: "GET",
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	}
	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	}
}

// Allowed for all account type
export const isAllowedToViewIndexView = (userContext) => {
	return true
}

// TODO Note : authorization check also done on backend 
export const isAllowedToViewFinancialGraph = (userContext) => {
	if (userContext.accountType === 'PRO' || isAdmin(userContext))
		return true
	else
		return false
}

// TODO Note : authorization check also done on backend 
export const isAllowedAGIEarningsSummary = (userContext) => {
	if (userContext.accountType === 'PRO' || isAdmin(userContext))
		return true
	else
		return false
}

// TODO Note : authorization check also done on backend 
export const isAllowedCandleProMode = (userContext) => {
	if (userContext.accountType === 'PRO' || isAdmin(userContext))
		return true
	else
		return false
}

// Note : authorization check also done on backend
export const isAdmin = (userContext) => {
	if (userContext?.accountType === 'ADMIN')
		return true
	else
		return false
}