import { Layout, Space, List } from 'antd';
import { XOutlined, YoutubeOutlined, InstagramOutlined, TikTokOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { IconSocialTiktok, IconSocialX } from './Icons';


const { Content, Footer } = Layout;

const linksJTI = [
    <Link to="/screener">Screener</Link>,
    <Link to="/watchlist">Watchlist</Link>,
    <Link to="/portfolio">Portfolio</Link>,
    <Link to="/tickeranalysis">Chart Analysis</Link>,
    // TODO : Direct link macro view pages
    <Link to="/macroview">Indexes View</Link>,
    <Link to="/macroview">Financial Graph</Link>,
]

const Resources = [
    <Link to="/aboutjti">About JTI</Link>,
    <Link to="/aianalysisdocu" >AI Analysis</Link>,
    <Link to="/blog" >Blog</Link>,
    <Link to="/pricing">Pricing</Link>,
]

const JTIFooter = () => {

    return (
        <Footer className='footer'>
            <Content className='footer-content'>
                <div className='footer-content-top'>
                    <div className='footer-link-block'>
                        <List style={{ float: 'right' }}
                            header={<div className='footer-list-header'>Resources</div>}
                            dataSource={Resources}
                            renderItem={(item) => (
                                <List.Item className='footer-list-item'>{item}</List.Item>
                            )}
                        />
                        {/* width: '150px' ortherwise deasable Resources links */}
                        <List style={{ width: '150px' }}
                            header={<div className='footer-list-header'>Just Trade It</div>}
                            dataSource={linksJTI}
                            renderItem={(item) => (
                                <List.Item className='footer-list-item'>{item}</List.Item>
                            )}
                        />
                    </div>
                    <div className='footer-social-block'>
                        <Link to="/"><img style={{ margin: '2em 0 0 2em' }} src="/logo_b.png" /></Link>
                        <div style={{ margin: '32px 0 0 0px', fontSize: '16px' }}>
                            Follow us :
                            <Space size={22} style={{ margin: '0px 0 0 24px', fontSize: '22px', }}>
                                <Link to='https://twitter.com/jtradeit' target="_blank"><XOutlined /></Link>
                                <Link to='https://www.youtube.com/@JTradeIt' target="_blank"><YoutubeOutlined /></Link>
                                <Link to='https://www.instagram.com/jtradeit' target="_blank"><InstagramOutlined /></Link>
                                <Link to='https://www.tiktok.com/@jtradeit' target="_blank"><TikTokOutlined /></Link>
                            </Space>
                        </div>
                    </div>
                </div>
                <div style={{ padding: '20px', fontSize: '15px', textAlign: 'left' }}>
                    <Space size={10} className='footer-legal-block'>
                        <Link to="/terms">Legal </Link> •
                        <Link to="/privacy"> Privacy </Link> •
                        <Link to="/terms"> Terms</Link>
                    </Space>
                    <div>© 2023 Just Trade It. All Rights Reserved</div>
                </div>
            </Content>
        </Footer>
    )
}

export default JTIFooter;