import { callExternalApi } from "./external-api.service";


const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const createUser = async (accessToken, user) => {
	const config = {
		url: `${apiServerUrl}/api/user/create`,
		method: "POST",
		data: user,
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};

export const getUser = async (accessToken) => {
	const config = {
		url: `${apiServerUrl}/api/user`,
		method: "GET",
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};

export const getUserList = async (accessToken) => {
	const config = {
		url: `${apiServerUrl}/api/user/list`,
		method: "GET",
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};

export const addTickerToWatchlist = async (accessToken, userId, ticker) => {
	const config = {
		url: `${apiServerUrl}/api/user/${userId}/watchlist`,
		method: "PUT",
		// ticker is convert to JSON format automatically {variableName: value}
		data: {
			ticker
		},
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};

export const deleteTickerFromWatchlist = async (accessToken, userId, ticker) => {
	const config = {
		url: `${apiServerUrl}/api/user/${userId}/watchlist`,
		method: "DELETE",
		// ticker is convert to JSON format automatically {variableName: value}
		data: {
			ticker
		},
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};

export const updateWatchlist = async (accessToken, userId, newWatchlist) => {
	const config = {
		url: `${apiServerUrl}/api/user/${userId}/watchlist`,
		method: "POST",
		// ticker is convert to JSON format automatically {variableName: value}
		data: {
			newWatchlist
		},
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	};
};

export const updateStockTradingBook = async (accessToken, userId, newStockTradingBook) => {
	const config = {
		url: `${apiServerUrl}/api/user/${userId}/stocktradingbook`,
		method: "POST",
		data: newStockTradingBook,
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		}
	}

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	}
}

export const updateUser = async (accessToken, user) => {
	const config = {
		url: `${apiServerUrl}/api/user`,
		method: "PUT",
		data: user,
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	}

	const { data, error } = await callExternalApi({ config });

	return {
		data: data || null,
		error,
	}
}
