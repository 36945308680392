import { Layout, Typography, } from 'antd';

const { Title } = Typography;


const Error = () => {
    return (
        <Layout className="site-layout-content-fixed">
            <Title level={2}>404</Title>
        </Layout>
    )
}

export default Error;