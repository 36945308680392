import { useEffect, useId } from 'react';
import { Typography, Modal, Space, Skeleton } from 'antd';
import Typewriter from 'typewriter-effect/dist/core';


const { Paragraph } = Typography;


/**
 * 
 * @param {props.open} If different than false, print text ortherwise show skeleton.
 * @returns 
 */
const ModalAGI = (props) => {

    // Manage case when component is used more than once in a page
    const id = 'typewriter' + useId()

    const handleCancel = () => {
        props.setOpen(false)
    }

    useEffect(() => {

        if (props.open && props.text !== false) {
            const typewriterElement = document.getElementById(id)

            const typewriter = new Typewriter(typewriterElement, {
                loop: false,
                delay: 80, // Adjust delay between words
            })

            typewriter.pasteString(props.text + '<br /><i>(AGI Beta)</i>')
            typewriter.start()
        }

    }, [props.open])

    return (
        <Modal open={props.open} width={1000} onCancel={handleCancel} footer={null}
            title={<Space align='center'>{props.icon}<span style={{ marginLeft: '0.3em', fontSize: '1.7em' }}>{props.title}</span></Space>}>

            {props.text !== false ? (
                <Paragraph id={id} style={{ marginTop: '2em', lineHeight: '2em', fontSize: '1.2em' }} />
            ) : (
                <Skeleton paragraph={{ rows: 4 }} />
            )}

        </Modal>
    )
}

export default ModalAGI;