import { HolderOutlined, BellOutlined, CloseOutlined } from '@ant-design/icons';
import { useSpring, animated } from 'react-spring';
import { theme, Layout, Typography, Button, Space, Table, Radio, Descriptions, Tooltip, Image, notification, Card } from 'antd';
import { IconLoading, IconSignalBuyL1, IconSignalBuyL2, IconSignalSellL1, IconSignalSellL2, IconSignalOff, IconWarnShortRatio, IconInHoldings } from '../components/Icons';
import React, { useContext, useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import UserContext from '../components/UserContext';
import { getTickersInfos, getTickersQuotes } from "../services/ticker.service";
import { formatNumberToB, formatPourcent, getAITag, displayData, pourcentage, formatPriceChg, getGainLossColor, getLogoUrl, navigateToTickerObject } from '../utils/utils'
import Column from 'antd/es/table/Column';
import ColumnGroup from 'antd/es/table/ColumnGroup';
import { DndContext } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Link } from 'react-router-dom';
import { TagClassResilient, TagClassYoung } from '../components/Tags';
import { InfoTradingProfileActiveDesc, InfoTradingProfileDCADesc, InfoTradingProfileSwingDesc, ReadTradingChecklist, UnauthorizedTradingProfile } from '../components/TempLang';
import { isMobile } from '../utils/utils';
import { TOOLTIP_ENTER_DELAY, tradingProfileOptions } from '../JTIConst'
import { NotifUpgradePlan } from '../components/Notifications';
import MenuCollapse from '../components/MenuCollapse';
import PageTitle1 from '../components/PageTitle1';

const { Text, Paragraph } = Typography;
const { Item, } = Descriptions;


// PARAMETERS //

const STYLE_RT_FONT_WEIGHT = 500
const REAL_TIME_INTERVAL = 6000     // Each 5sec
const REAL_TIME_COUNT_OFF = 20      // Real time interval stop when counter reach x (no new quotes x times)


/**
 * Implemented from example : https://ant.design/components/table#components-table-demo-drag-sorting-handler
 */
const Watchlist = () => {

    const {
        token: { colorPrimary },
    } = theme.useToken();

    const [springs, api] = useSpring(() => ({
        config: { duration: 800 },
        from: { opacity: 0.4 },
        to: {
            opacity: 1,
        },
    }))
    const animateChanges = () => {
        api.start({
            from: { opacity: 0.4 },
            to: {
                opacity: 1,
            },
        })
    }

    const [isLoading, setIsLoading] = useState(true)
    const { getAccessTokenSilently } = useAuth0()
    const { userContext, updateUserWatchlist, delTickerFromWatchlist, updateUserContext, switchAlert, doIHold } = useContext(UserContext);
    const [tableData, setTableData] = useState(null)
    const [selectedTradingProfile, setSelectedTradingProfile] = useState('')
    const [notifApi, contextHolder] = notification.useNotification();

    const tradingProfileDesc = [
        (<Text>{InfoTradingProfileDCADesc}<br /><ReadTradingChecklist /></Text>),
        (<Text>{InfoTradingProfileSwingDesc}<br /><ReadTradingChecklist /></Text>),
        (<Text>{InfoTradingProfileActiveDesc}<br /><ReadTradingChecklist /></Text>)
    ]

    // Update trading profile description
    const [selectedTradingProfileDesc, setSelectedTradingProfileDesc] = useState('')
    const updTradingProfileDesc = (profile) => {

        if (profile === 'DCA')
            setSelectedTradingProfileDesc(tradingProfileDesc[0])
        else if (profile === 'SWING')
            setSelectedTradingProfileDesc(tradingProfileDesc[1])
        else if (profile === 'ACTIVE')
            setSelectedTradingProfileDesc(tradingProfileDesc[2])
    }

    // Update user trading profile
    const updTradingProfile = async (evt) => {

        const profile = evt.target.value
        const oldProfile = userContext.tradingProfile
        userContext.tradingProfile = profile

        const success = await updateUserContext(userContext)

        if (success) {
            setSelectedTradingProfile(profile)
            updTradingProfileDesc(evt.target.value)
        } else {
            userContext.tradingProfile = oldProfile
            notifApi.info(NotifUpgradePlan(UnauthorizedTradingProfile))
        }
    }

    // Manage real time price update
    var countOff = 1
    var intervalId = null
    const updatePrices = async () => {

        const accessToken = await getAccessTokenSilently()
        const quotesResp = await getTickersQuotes(accessToken, userContext.watchlist.map(ticker => ticker.ticker))
        const quotes = quotesResp.data

        setTableData((prevTableData) => {
            return prevTableData?.map(item => {
                const lastQuote = quotes?.[item.key]

                // If quote response is different than current, update last price and change
                if (lastQuote && lastQuote.close && lastQuote.prevClose && lastQuote.close.toFixed(2) != item.lastPrice) {
                    countOff = 0
                    return { ...item, lastPrice: lastQuote.close.toFixed(2), change: pourcentage(lastQuote.close, lastQuote.prevClose), animate: true }
                }

                // If quote response is same as current, keep the item as is
                return { ...item, animate: false }
            })
        })

        // Stop real time if counter is reach > clear interval
        if (countOff >= REAL_TIME_COUNT_OFF)
            clearInterval(intervalId)
        else
            countOff++
    }

    const paginationOptions = {
        defaultPageSize: 100,
        showSizeChanger: false,
    }

    const Row = ({ children, ...props }) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            setActivatorNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({
            id: props['data-row-key'],
        })

        const style = {
            ...props.style,
            transform: CSS.Transform.toString(transform && {
                ...transform,
                // scaleY: 1,
            }),
            transition,
            ...(isDragging ? { position: 'relative', zIndex: 9999, } : {}),
        }

        return (
            <tr {...props} ref={setNodeRef} style={style} {...attributes}>
                {/* 
                In React, React.Children.map and children.map are used to iterate over the children prop of a component, but they have some differences in behavior.

                React.Children.map is a method provided by React that allows you to iterate over the children prop of a component.
                It handles cases where the children prop may be null or undefined gracefully and returns the expected result.
                It provides an alternative to using children.map directly when you want to ensure that the children prop exists and is iterable.
                It is a safer and more reliable way to iterate over the children prop.
                 */}
                {React.Children.map(children, (child) => {
                    if (child.key === 'sort') {
                        return React.cloneElement(child, {
                            children: (
                                <HolderOutlined ref={setActivatorNodeRef} style={{
                                    touchAction: 'none',
                                    cursor: 'move',
                                }}
                                    {...listeners}
                                />
                            ),
                        });
                    }
                    return child;
                })}
            </tr>
        )
    }

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            const activeIndex = tableData.findIndex((i) => i.key === active.id);
            const overIndex = tableData.findIndex((i) => i.key === over?.id);
            const newTableData = arrayMove(tableData, activeIndex, overIndex);
            setTableData(newTableData)

            const newWatchlist = arrayMove(userContext.watchlist, activeIndex, overIndex)
            updateUserWatchlist(newWatchlist)
        }
    }

    const handleAlert = async (e, itemId) => {
        e.stopPropagation()     // Prevent event propagation to parent elements

        switchAlert(itemId)

        // Update table
        const idx = tableData.findIndex(record => record.key === itemId)
        if (idx !== -1) {   // Ticker found
            tableData[idx].alert = !tableData[idx].alert
            setTableData([...tableData]);
        } else {
            console.log(`Record key '${itemId}' not found in watchlist table`);
        }
    }

    const handleDelete = async (e, itemId) => {
        e.stopPropagation()     // Prevent event propagation to parent elements

        delTickerFromWatchlist(itemId)
        setTableData(tableData.filter((item) => item.key !== itemId));
    }

    useEffect(() => {

        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();
            const datas = []

            if (userContext != null && userContext.watchlist.length > 0) {

                setSelectedTradingProfile(userContext.tradingProfile)
                updTradingProfileDesc(userContext.tradingProfile)

                const resp = await getTickersInfos(accessToken, userContext.watchlist.map(ticker => ticker.ticker))

                // Manage error
                if (resp.error === 500)
                    window.location.reload()

                const tickersInfosList = resp.data
                for (const tickerUserParam of userContext.watchlist) {

                    const tkrInfos = tickersInfosList[tickerUserParam.ticker]

                    if (tkrInfos) {
                        const tkrQuote = tkrInfos.quote
                        let priceChange = pourcentage(tkrQuote.close, tkrQuote.prevClose)

                        // Define tags
                        const tagResilient = tkrInfos.tags?.resilient ? <TagClassResilient /> : ''
                        const tagYoung = tkrInfos.tags?.young ? <TagClassYoung /> : ''
                        const modelEfficiencyTag = getAITag(tkrInfos.oneDay?.total_win_percent)
                        const tagsNode = <Space size={[1, 5]} wrap>{modelEfficiencyTag}{tagResilient}{tagYoung}</Space>

                        datas.push({
                            key: tkrInfos.ticker,
                            symbol: tkrInfos.ticker,
                            lastPrice: tkrQuote.close.toFixed(2),
                            currency: tkrQuote.currency,
                            priceChange: priceChange,
                            priceChangeStyle: { color: getGainLossColor(priceChange), fontWeight: STYLE_RT_FONT_WEIGHT },
                            marketCap: tkrInfos.marketCap,
                            dividendYield: tkrInfos.dividendYield,
                            exDividendDate: tkrInfos.exDividendDate,
                            shortRatio: tkrInfos.shortRatio,
                            tags: tagsNode,
                            totalWinPerct: tkrInfos.oneDay.total_win_percent,
                            roiAVGAll: tkrInfos.oneDay.roi_avg_all,
                            activeSignalBuyL1: tkrInfos.oneDay.activeSignalBuyL1,
                            activeSignalBuyL2: tkrInfos.oneDay.activeSignalBuyL2,
                            activeSignalSellL1: tkrInfos.oneDay.activeSignalSellL1,
                            activeSignalSellL2: tkrInfos.oneDay.activeSignalSellL2,
                            alert: tickerUserParam.alert,
                            animate: false
                        })
                    }
                }

                setIsLoading(false)
            }

            setTableData(datas)
        }
        fetchData()

        if (intervalId == null) {
            intervalId = setInterval(updatePrices, REAL_TIME_INTERVAL)

            // Clear interval on component unmount
            return () => clearInterval(intervalId)
        }
    }, [userContext])

    // userContext needs to be setted otherwise error
    if (userContext == null || (userContext.watchlist.length > 0 && (tableData == null || isLoading))) {
        return (
            <Layout className="site-layout-content">
                <IconLoading />
            </Layout>)
    }

    if (userContext.watchlist.length === 0) {
        return (
            <Layout className="site-layout-content">
                <PageTitle1> • Watchlist</PageTitle1>

                <Paragraph>
                    Your watchlist is currently empty. Please start by adding symbols to your watchlist from the <Link to="/tickeranalysis">Chart Analysis</Link> page.
                </Paragraph>
            </Layout>)
    }

    const menuItem = [{
        label: <div className="menu-title">STRATEGY</div>,
        children:
            <Descriptions column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 7 }} labelStyle={{ color: 'black', fontWeight: '430' }}>
                <Item label="• Trading Profile" span={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 3 }}>
                    <Radio.Group optionType="button" buttonStyle="solid"
                        options={tradingProfileOptions}
                        onChange={updTradingProfile}
                        value={selectedTradingProfile}
                    />
                </Item>
                <Item contentStyle={{ borderBottom: '1px solid #000', paddingBottom: '1em' }} span={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 4 }}>
                    {selectedTradingProfileDesc}
                </Item>
            </Descriptions>
    }]

    return (
        <Layout className="site-layout-content">
            {/* TODO : Create components for titles t1 t2... */}
            <PageTitle1> • Watchlist</PageTitle1>

            <MenuCollapse items={menuItem} />

            <Card className='card'>
                <DndContext onDragEnd={onDragEnd}>
                    <SortableContext
                        // rowKey array
                        items={tableData.map((i) => i.key)}
                        strategy={verticalListSortingStrategy}>
                        <Table className='table' size='small' dataSource={tableData} components={{ body: { row: Row, }, }} pagination={paginationOptions}
                            rowClassName={'table-row-pointer'}
                            onRow={(record, rowIndex) => navigateToTickerObject(record.key)}>
                            <Column align='center' key='sort' width={40} />
                            <Column title="Symbol" dataIndex="symbol" key="symbol" onHeaderCell={() => ({
                                // TODO : Table column alignment
                                // className: 'table-header-center'
                            })}
                                render={(_, record) => {
                                    // MOBILE
                                    if (isMobile()) {
                                        var styleAnimate = { fontWeight: STYLE_RT_FONT_WEIGHT, fontSize: '0.9em' }
                                        if (record.animate)
                                            styleAnimate = { ...styleAnimate, ...springs }
                                        return (
                                            <>
                                                <Space align="center">
                                                    <Image src={getLogoUrl(record.symbol)} width={15} preview={false} />
                                                    <Text>{record.symbol} {doIHold(record.symbol) && <IconInHoldings />}</Text>
                                                </Space>
                                                <animated.div style={styleAnimate}>
                                                    {record.lastPrice + ' ' + record.currency[0]} <span style={record.priceChangeStyle}>({formatPriceChg(record.priceChange)})</span>
                                                </animated.div>
                                            </>)
                                    } else
                                        return (
                                            <Space align="center">
                                                <Image src={getLogoUrl(record.symbol)} width={16} preview={false} />
                                                <Text>{record.symbol} {doIHold(record.symbol) && <IconInHoldings />}</Text>
                                            </Space>)
                                }} />

                            {/* MOBILE */}
                            <Column title="Last Price" dataIndex="lastPrice" key="lastPrice" width='5%' responsive={['sm']}
                                render={(_, record) => {
                                    var style = { fontWeight: STYLE_RT_FONT_WEIGHT }
                                    if (record.animate)
                                        style = { ...style, ...springs }

                                    return (
                                        <animated.span style={style}>
                                            {record?.lastPrice + ' ' + record?.currency?.[0]}
                                        </animated.span>)
                                }} />
                            <Column title="Change" align='center' dataIndex="change" key="change" responsive={['sm']}
                                render={(_, record) => {
                                    var style = record.priceChangeStyle
                                    if (record.animate)
                                        style = { ...style, ...springs }

                                    return (<animated.span style={style}>{formatPriceChg(record.priceChange)}</animated.span>)
                                }} />
                            <Column title={<><span className='ai-logo'>AI</span> Tags</>} align='left' dataIndex="tags" key="tags" width='19.5%' responsive={['sm']} />
                            <Column title="Market Cap" align='center' dataIndex="marketCap" key="marketCap" responsive={['sm']}
                                render={(_, record) => formatNumberToB(record.marketCap)} />
                            <Column title="Dividends Yield" align='center' dataIndex="dividendYield" key="dividendYield" width='5%' responsive={['sm']}
                                render={(_, record) =>
                                    displayData(record.dividendYield) === '-' ? '-' :
                                        formatPourcent(record.dividendYield)} />
                            <Column title="Ex-Dividend Date" align='center' dataIndex="exDividendDate" key="exDividendDate" width='8%' responsive={['sm']}
                                render={(_, record) => displayData(record.exDividendDate)} />
                            <Column title="Short Ratio" align='center' dataIndex="shortRatio" key="shortRatio" responsive={['sm']}
                                render={(_, record) =>
                                    displayData(record.shortRatio) === '-' ? '-' :
                                        record.shortRatio < 4 ? record.shortRatio + '%' : (<>{record.shortRatio + '%'}<IconWarnShortRatio /></>)} />

                            <Column title="% Win" align='center' dataIndex="totalWinPerct" key="totalWinPerct" responsive={['sm']}
                                render={(_, record) => record.totalWinPerct.toFixed(2) + '%'} />
                            <Column title="AVG ROI" align='center' dataIndex="roiAVGAll" key="roiAVGAll" responsive={['sm']}
                                render={(_, record) => record.roiAVGAll.toFixed(2) + '%'} />

                            <ColumnGroup title='Active Buy Signal' >
                                <Column title={<><IconSignalBuyL1 /> L1</>} align='center' dataIndex="activeSignalBuyL1" key="activeSignalBuyL1"
                                    render={(_, record) => record.activeSignalBuyL1 ? <IconSignalBuyL1 /> : <IconSignalOff />} />
                                <Column title={<><IconSignalBuyL2 /> L2</>} align='center' dataIndex="activeSignalBuyL2" key="activeSignalBuyL2"
                                    render={(_, record) => record.activeSignalBuyL2 ? <IconSignalBuyL2 /> : <IconSignalOff />} />
                            </ColumnGroup>
                            <ColumnGroup title='Active Sell Signal' >
                                <Column title={<><IconSignalSellL1 /> L1</>} align='center' dataIndex="activeSignalSellL1" key="activeSignalSellL1"
                                    render={(_, record) => record.activeSignalSellL1 ? <IconSignalSellL1 /> : <IconSignalOff />} />
                                <Column title={<><IconSignalSellL2 /> L2</>} align='center' dataIndex="activeSignalSellL2" key="activeSignalSellL2"
                                    render={(_, record) => record.activeSignalSellL2 ? <IconSignalSellL2 /> : <IconSignalOff />} />
                            </ColumnGroup>
                            <Column title="" key="action" align='center' width={40} render={(_, record) => (
                                <Space.Compact>
                                    <Tooltip placement='top' color='orange' title="Activate signal notification" mouseEnterDelay={TOOLTIP_ENTER_DELAY}>
                                        <Button type="text" icon={<BellOutlined style={{ fontSize: '14px', color: record.alert ? colorPrimary : '' }} />} onClick={(e) => handleAlert(e, record.key)} />
                                    </Tooltip>
                                    <Tooltip placement='top' color='orange' title="Delete from watchlist" mouseEnterDelay={TOOLTIP_ENTER_DELAY}>
                                        <Button type="text" icon={<CloseOutlined style={{ fontSize: '12px' }} />} onClick={(e) => handleDelete(e, record.key)} />
                                    </Tooltip>
                                </Space.Compact>
                            )} />
                        </Table>
                    </SortableContext>
                </DndContext>
            </Card>

            <div className='legend-box'>
                <Text className='legend-text' italic>The AI Model data is for the daily chart.</Text>
            </div>

            {animateChanges()}
            {contextHolder}
        </Layout>
    )
}

export default Watchlist