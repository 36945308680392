import { Button } from 'antd';

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const SignupButton = ({ label, ...props }) => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/newuserstartup",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };

  return (
    <Button {...props} onClick={handleSignUp}>
      {label}
    </Button>
  );
};
